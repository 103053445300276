export default function PrivacyAgreement({ show, close }) {

   if(!show){
      return false;
   }

   return (
      <div className="privacy_agreement_all_wrap">
         <div className="privacy_agreement_all">
            <div className="title_wrap">
               <h4 className="xl:text-18 text-14 fw_type_03">개인정보 처리방침</h4>
               <button onClick={close}>
                  <i className="xi-close fs_type_06"></i>
               </button>
            </div>
            <div className="text_wrap">
               <h5 className="xl:text-16 text-12 font-medium xl:mb-16 mb-8">개인정보 수집 • 이용 내역</h5>
               <div>
                  <div className="item xl:mb-16 mb-8">
                     <h6 className="tit xl:text-14 text-12 fw_type_light">항목 (필수항목)</h6>
                     <p className="xl:text-14 text-12 fw_type_light">성명, 연락처, 이메일</p>
                  </div>
                  <div className="item xl:mb-16 mb-8">
                     <h6 className="tit xl:text-14 text-12 fw_type_light">수집 • 이용 목적</h6>
                     <p className="xl:leading-20 leading-16 xl:text-14 text-12 fw_type_light">
                        고객문의는 최소한의 개인정보만을 수집합니다.
                        상담내용확인 및 상담결과 회신 서비스 제공을
                        목적으로 개인정보 수집 이용 동의를 구합니다.
                     </p>
                  </div>
                  <div className="item xl:mb-16 mb-8">
                     <h6 className="tit xl:text-14 text-12 fw_type_light">보유기간</h6>
                     <p className="xl:leading-20 leading-16 xl:text-14 text-12 fw_type_light">
                        개인정보 수집 및 이용목적이 달성된 후에는
                        해당 정보를 지체없이 파기합니다.
                        (단, 타 법령에 의해 보존의무가 있는 경우
                        법령이 지정한 기간 동안 보존합니다.)
                     </p>
                  </div>
               </div>
            </div>
         </div >
      </div>
   )
}