import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// images Start
import logo from "../images/common/logo_type01.webp"
import global from "../images/common/icon_global.webp"
import { useRef, useState } from "react";
// images End

export default function Header() {

   const { i18n } = useTranslation();
   const { t } = useTranslation();

   const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      window.location.reload();
   };

   const gnbActiveWrapRef = useRef(null);

   const openGnb = () => {
      if (gnbActiveWrapRef.current) {
         gnbActiveWrapRef.current.classList.add('on');
      }
   };
   const closeGnb = () => {
      if (gnbActiveWrapRef.current) {
         setActiveIndex(null);
         gnbActiveWrapRef.current.classList.remove('on');
      }
   };

   const [activeIndex, setActiveIndex] = useState(null);

   const handleToggle = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
   };

   if (gnbActiveWrapRef.current) {
      document.querySelectorAll(".dep02_item").forEach((element) => {
         element.addEventListener("click", () => {
            closeGnb();
         })
      })
   }

   const menuItems = [
      {
         title: '회사소개',
         subItems: [
            { name: '핵심가치', link: '/company/values' },
            { name: '비전과 미션', link: '/company/vision' },
            { name: '문의하기', link: '/company/contact' },
         ],
      },
      {
         title: '수학 교육의 표준',
         subItems: [
            { name: '수학의 본질', link: '/math/essence' },
            { name: '바람직한 수학 교육', link: '/math/ideal' },
            { name: '제너럴매스', link: '/math/general' },
         ],
      },
      {
         title: '소비자 중심의 교육',
         subItems: [
            { name: 'IT 기반 수학 교육 서비스', link: '/education/it-math' },
            { name: 'AR 기반 수학 교육 서비스', link: '/education/ar-math' },
            { name: 'DT 기반 수학 교육 서비스', link: '/education/dt-math' },
         ],
      },
      {
         title: '제너럴매스 144',
         subItems: [
            { name: '초등 교육 과정', link: '/curriculum-general/elementary' },
            { name: '중고등 교육 과정', link: '/curriculum-general/secondary' },
         ],
      },
      {
         title: '키즈-프로기 144',
         subItems: [
            { name: '유아부 교육 과정', link: '/curriculum-kids/infant' },
            { name: '유치부 교육 과정', link: '/curriculum-kids/preschool' },
         ],
      },
      {
         title: 'Liberal Arts Program',
         subItems: [
            { name: '프로그램 개요', link: '/arts/overview' },
            { name: '교육 커리큘럼', link: '/arts/curriculum' },
            { name: '예비 진단 프로그램', link: '/arts/preliminary' },
            { name: '회원 가입', link: '/arts/membership' },
         ],
      },
      {
         title: '블루베리 에스프레소',
         subItems: [
            { name: '수학편지', link: '/blueberry-espresso/math-letters' },
            { name: '수학농장', link: '/blueberry-espresso/math-farm' },
            { name: '수학의 띠 운동', link: '/blueberry-espresso/math-ribbon' },
            { name: '회사 소식', link: '/blueberry-espresso/news' },
         ],
      },
   ];

   return (
      <div className="header_wrap">
         <header id="header">
            <nav id="gnb">
               <ul className="gnb_ul">
                  <li>
                     <Link to="/">
                        <img className="logo" src={logo} alt="logo" />
                     </Link>
                  </li>
                  <li className="nav_li">
                     <ul className="gnb_dep2 nav_dep2">
                        <li className="gnb_dep2_item">
                           <Link to="/company/values" className="btn_link">{t("header01")}</Link>
                           <ul className="gnb_dep3">
                              <li>
                                 <Link to="/company/values" className="btn_link">핵심가치</Link>
                              </li>
                              <li>
                                 <Link to="/company/vision" className="btn_link">비전과 미션</Link>
                              </li>
                              <li>
                                 <Link to="/company/contact" className="btn_link">문의하기</Link>
                              </li>
                           </ul>
                        </li>
                        <li className="gnb_dep2_item">
                           <Link to="/math/essence" className="btn_link">{t("header02")}</Link>
                           <ul className="gnb_dep3">
                              <li>
                                 <Link to="/math/essence" className="btn_link">수학의 본질</Link>
                              </li>
                              <li>
                                 <Link to="/math/ideal" className="btn_link">바람직한 수학 교육</Link>
                              </li>
                              <li>
                                 <Link to="/math/general" className="btn_link">제너럴매스</Link>
                              </li>
                           </ul>
                        </li>
                        <li className="gnb_dep2_item">
                           <Link to="/education/it-math" className="btn_link">{t("header03")}</Link>
                           <ul className="gnb_dep3">
                              <li>
                                 <Link to="/education/it-math" className="btn_link">IT 기반 수학 교육 서비스</Link>
                              </li>
                              <li>
                                 <Link to="/education/ar-math" className="btn_link">AR 기반 수학 교육 서비스</Link>
                              </li>
                              <li>
                                 <Link to="/education/dt-math" className="btn_link">DT 기반 수학 교육 서비스</Link>
                              </li>
                           </ul>
                        </li>
                        <li className="gnb_dep2_item">
                           <Link to="/curriculum-general/elementary" className="btn_link">{t("header04")}</Link>
                           <ul className="gnb_dep3">
                              <li>
                                 <Link to="/curriculum-general/elementary" className="btn_link">초등 교육 과정</Link>
                              </li>
                              <li>
                                 <Link to="/curriculum-general/secondary" className="btn_link">중고등 교육 과정</Link>
                              </li>
                           </ul>
                        </li>
                        <li className="gnb_dep2_item">
                           <Link to="/curriculum-kids/infant" className="btn_link">{t("header05")}</Link>
                           <ul className="gnb_dep3">
                              <li>
                                 <Link to="/curriculum-kids/infant" className="btn_link">유아부 교육 과정</Link>
                              </li>
                              <li>
                                 <Link to="/curriculum-kids/preschool" className="btn_link">유치부 교육 과정</Link>
                              </li>
                           </ul>
                        </li>
                        <li className="gnb_dep2_item">
                           <Link to="/arts/overview" className="btn_link">{t("header06")}</Link>
                           <ul className="gnb_dep3">
                              <li>
                                 <Link to="/arts/overview" className="btn_link">프로그램 개요</Link>
                              </li>
                              <li>
                                 <Link to="/arts/curriculum" className="btn_link">교육 커리큘럼</Link>
                              </li>
                              <li>
                                 <Link to="/arts/preliminary" className="btn_link">예비 진단 프로그램</Link>
                              </li>
                              <li>
                                 <Link to="/arts/membership" className="btn_link">회원 가입</Link>
                              </li>
                           </ul>
                        </li>
                        <li className="gnb_dep2_item">
                           <Link to="/blueberry-espresso/math-letters" className="btn_link">{t("header07")}</Link>
                           <ul className="gnb_dep3">
                              <li>
                                 <Link to="/blueberry-espresso/math-letters" className="btn_link">수학편지</Link>
                              </li>
                              <li>
                                 <Link to="/blueberry-espresso/math-farm" className="btn_link">수학농장</Link>
                              </li>
                              <li>
                                 <Link to="/blueberry-espresso/math-ribbon" className="btn_link">수학의 띠 운동</Link>
                              </li>
                              <li>
                                 <Link to="/blueberry-espresso/news" className="btn_link">회사소식</Link>
                              </li>
                           </ul>
                        </li>
                     </ul>
                  </li>
                  <li className="gnb_dep2">
                     <div className="global_wrap gnb_dep2_item">
                        <button className="btn_global">
                           <img className="icon_global" src={global} alt="global" />
                        </button>
                        <ul className="global_dep2 gnb_dep3">
                           <li>
                              <button className="btn_link" onClick={() => changeLanguage('ko')}>한국어</button>
                           </li>
                           <li>
                              {/* <button className="btn_link" onClick={() => changeLanguage('en')}>English</button> */}
                              <button className="btn_link">English</button>
                           </li>
                        </ul>
                     </div>
                     <button onClick={() => openGnb()} className="open_gnb xi-bars"></button>
                  </li>
               </ul>
            </nav>
         </header>
         <div ref={gnbActiveWrapRef} className="gnb_active_wrap">
            <div className="top_menu">
               <img className="logo mo_only" src={logo} alt="logo" />
               <button onClick={closeGnb} className="btn_close">
                  <i className="xi-close xl:text-32 text-16"></i>
               </button>
            </div>
            <ul className="gnb_active">
               {menuItems.map((item, index) => (
                  <li className="dep01_item" key={index}>
                     <button
                        className={`tit xl:text-28 text-16 font-semibold ${activeIndex === index ? 'on' : ''}`}
                        onClick={() => handleToggle(index)}
                     >
                        <p>{item.title}</p>
                        <i
                           className={`mo_only icon_arrow text-15 xi-angle-down-min ${activeIndex === index ? 'rotate-180' : ''
                              }`}
                        ></i>
                     </button>
                     <ul
                        className={`dep02_item_wrap ${activeIndex === index ? 'on' : ''}`}
                     >
                        {item.subItems.map((subItem, subIndex) => (
                           <li key={subIndex}>
                              <Link className="dep02_item xl:text-20 text-13" to={subItem.link}>
                                 {subItem.name}
                              </Link>
                           </li>
                        ))}
                     </ul>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   )
}