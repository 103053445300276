import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

// CSS Start
// CSS End

export default function ProductPreview({ data, show, close }) {

   if (!show) {
      return false;
   }

   return (
      <div className="product_preview_wrap">
         <Swiper
            className="product_preview_swiper"
            pagination={true}
            navigation={{
               prevEl: ".btn_prev",
               nextEl: ".btn_next",
            }}
            loop={false}
            modules={[Pagination, Navigation]}
         >
            {data ? (
               data.imageDetails.map((imageDetails, index) => (
                  <SwiperSlide key={index}>
                     <img className="slide_img" src={process.env.REACT_APP_API_URL+imageDetails.path} alt="미리보기 이미지" />
                  </SwiperSlide>
               ))
            ) : (
               <div className="hide_txt">Loading</div>
            )}
            <div className="navigation_wrap">
               <button className="btn_prev">
                  <i className="xi-angle-left xl:text-13"></i>
               </button>
               <button className="btn_next">
                  <i className="xi-angle-right xl:text-13"></i>
               </button>
            </div>
            <button onClick={() => { close() }} className="btn_close">
               <i className="xi-close text-white"></i>
            </button>
         </Swiper>
      </div>
   )
}