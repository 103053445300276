import { Routes, Route } from "react-router-dom";
import './i18n';

// components Start
import ScrollToTop from "./components/ScrollToTop"
import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./components/PageNotFound";
import ProductDetailPage from "./components/ProductDetailPage";
import TopBtn from "./components/TopBtn";
// components End

// Pages Start
import MainPage from "./pages/MainPage";
import CoreValues from "./pages/company/CoreValues"
import VisionMission from "./pages/company/VisionMission"
import ContactUs from "./pages/company/ContactUs"
import MathEssenceIntro from "./pages/math-education-standards/MathEssenceIntro";
import GeneralMath from "./pages/math-education-standards/GeneralMath";
import IdealMathEducation from "./pages/math-education-standards/IdealMathEducation";
import ItBasedMathEducationService from "./pages/consumer-centric-education/ItBasedMathEducationService"
import ArBasedMathEducationService from "./pages/consumer-centric-education/ArBasedMathEducationService"
import DtBasedMathEducationService from "./pages/consumer-centric-education/DtBasedMathEducationService"
import ElementaryEducationCurriculum from "./pages/general-math-144/ElementaryEducationCurriculum";
import SecondaryEducationCurriculum from "./pages/general-math-144/SecondaryEducationCurriculum";
import InfantEducationCurriculum from "./pages/kids-froggy-144/InfantEducationCurriculum";
import PreSchoolEducationCurriculum from "./pages/kids-froggy-144/PreSchoolEducationCurriculum";
import ProgramOverview from "./pages/liberal-arts-program/ProgramOverview";
import EducationCurriculum from "./pages/liberal-arts-program/EducationCurriculum";
import PreliminaryDiagnosticProgram from "./pages/liberal-arts-program/PreliminaryDiagnosticProgram";
import Membership from "./pages/liberal-arts-program/Membership";
import MathLetters from "./pages/blueberry-espresso/MathLetters";
import MathFarm from "./pages/blueberry-espresso/MathFarm";
import MathNoticeDetail from "./pages/blueberry-espresso/MathNoticeDetail";
import MathRibbon from "./pages/blueberry-espresso/MathRibbon"
import News from "./pages/blueberry-espresso/News"
import LettersDetail from "./pages/blueberry-espresso/LettersDetail";
import NewsDetail from "./pages/blueberry-espresso/NewsDetail";
// Pages End

// CSS Start
import './css/normalize.css'
import './css/common.css'
import './css/style.css'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// CSS End

export default function Main() {

  ScrollToTop();

  return (
    <div>
      <Header />

      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/company/values" element={<CoreValues />} />
        <Route path="/company/vision" element={<VisionMission />} />
        <Route path="/company/contact" element={<ContactUs />} />
        <Route path="/math/essence" element={<MathEssenceIntro />} />
        <Route path="/math/ideal" element={<IdealMathEducation />} />
        <Route path="/math/general" element={<GeneralMath />} />
        <Route path="/education/it-math" element={<ItBasedMathEducationService />} />
        <Route path="/education/ar-math" element={<ArBasedMathEducationService />} />
        <Route path="/education/dt-math" element={<DtBasedMathEducationService />} />
        <Route path="/curriculum-general/elementary" element={<ElementaryEducationCurriculum />} />
        <Route path="/curriculum-general/secondary" element={<SecondaryEducationCurriculum />} />
        <Route path="/curriculum-kids/infant" element={<InfantEducationCurriculum />} />
        <Route path="/curriculum-kids/preschool" element={<PreSchoolEducationCurriculum />} />
        <Route path="/curriculum/:id" element={<ProductDetailPage />} />
        <Route path="/arts/overview" element={<ProgramOverview />} />
        <Route path="/arts/curriculum" element={<EducationCurriculum />} />
        <Route path="/arts/preliminary" element={<PreliminaryDiagnosticProgram />} />
        <Route path="/arts/membership" element={<Membership />} />
        <Route path="/blueberry-espresso/math-letters" element={<MathLetters />} />
        <Route path="/blueberry-espresso/math-letters/:id" element={<LettersDetail />} />
        <Route path="/blueberry-espresso/math-farm" element={<MathFarm />} />
        <Route path="/blueberry-espresso/math-farm/:id" element={<MathNoticeDetail />} />
        <Route path="/blueberry-espresso/math-ribbon" element={<MathRibbon />} />
        <Route path="/blueberry-espresso/math-ribbon/:id" element={<MathNoticeDetail />} />
        <Route path="/blueberry-espresso/news" element={<News />} />
        <Route path="/blueberry-espresso/news/:id" element={<NewsDetail />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
      <TopBtn />
    </div>
  );
}