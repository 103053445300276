import { withTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from "react-router-dom";

// Components Start
import SubPageBanner from "../../components/SubPageBanner";
import PageControl from '../../components/PageControl';
// Components Start

// Images Start
import axios from 'axios';
import { useEffect, useState } from 'react';
// Images End

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

const MathLetters = ({ t }) => {

   const query = useQuery();
   const page = query.get('page');
   const search = query.get('search');

   const [data, setData] = useState();
   const [pageLink, setPageLink] = useState(page ? page : 1);

   const [searchTerm, setSearchTerm] = useState(search ? search : '');
   const [searchTermWrap, setSearchTermWrap] = useState(search ? search : '');
   const [activeIndex, setActiveIndex] = useState(page ? page : 1);

   const navigate = useNavigate();

   const handleChange = (event) => {
      setSearchTermWrap(event.target.value);
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      const regex = /^[a-zA-Z0-9가-힣\s]*$/;

      if (!regex.test(searchTermWrap)) {
         alert("영문[대소문자], 한글[자음+모음], 숫자로 입력해주세요");
         return;
      }

      setActiveIndex(1);
      setPageLink(1)
      setSearchTerm(searchTermWrap);
      navigate(`?page=${pageLink}&search=${searchTermWrap}`);
   };

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/letters?lang=${t("lang")}&perPage=12&page=${pageLink}&search=${searchTerm}`);
            setData(response.data);
            if (response.data.data.state === false) {
               alert("일시적인 오류가 발생했습니다.")
            }
         } catch (error) {
            console.log(error);
         }
      };

      fetchData();
      navigate(`?page=${pageLink}&search=${searchTerm}`);
   }, [t, searchTerm, pageLink, page, search, navigate]);

   return (
      <div className="math_letters blueberry_espresso sub_page">
         <SubPageBanner
            category="블루베리 에스프레소"
            title="블루베리 에스프레소 내용을 압력해주세요."
            navDep_01="블루베리 에스프레소"
            navDep_02="수학편지"
         />
         <section className="section01 wrap">
            <h3 className="text-center xl:text-44 text-22 font-bold">수학편지</h3>
            <p className="text-center xl:leading-32 leading-20 xl:text-20 text-13 xl:mt-24 mt-16">수학편지에 관련된 설명을 입력해주세요.</p>
            <div className="top_nav type_01 xl:mt-40 mt-24">
               <form className="search_from" onSubmit={handleSubmit}>
                  <fieldset>
                     <legend className="hide">검색</legend>
                     <div className="search_box">
                        <input
                           id="search"
                           className="text-14"
                           type="search"
                           name="search"
                           placeholder="검색어를 입력하세요"
                           value={searchTermWrap}
                           onChange={handleChange}
                        />
                        <button onClick={handleSubmit} type="button">
                           <i className="xi-search icon_search" />
                        </button>
                     </div>
                  </fieldset>
               </form>
            </div>
            <div className="section02">
               <ul className="list_wrap">
                  {
                     data ? (
                        (data.meta.total === 0 ? (
                           <li className="item noitem txt_align_center">게시글이 없습니다.</li>
                        ) : (
                           data.data.map((letterList, index) => (
                              <li className="list" key={index}>
                                 <Link to={`/blueberry-espresso/math-letters/${letterList.id}?page=${pageLink}&search=${searchTerm}`}>
                                    <div className="thumbnail_wrap">
                                       <img className="thumbnail" src={`https://img.youtube.com/vi/${letterList.youtube}/hqdefault.jpg`} alt="" />
                                       <div className="icon_bg">
                                          <i className="icon xi-search xl:text-44 text-22"></i>
                                       </div>
                                    </div>
                                    <p className="text-center xl:leading-32 leading-20 xl:text-20 text-13 font-semibold xl:mt-24 mt-16">{letterList.title}</p>
                                 </Link>
                              </li>
                           ))
                        ))
                     ) : (
                        <li className='hide_txt'>Loading ...</li>
                     )
                  }
               </ul>
            </div>
            {data &&
               <PageControl
                  data={data}
                  pageLink={pageLink}
                  setPageLink={setPageLink}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
               />}
         </section>
      </div>
   )
}

export default withTranslation()(MathLetters)