import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper/modules';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { withTranslation, Trans } from 'react-i18next';

// Components Start
import PrivacyAgreement from "../components/PrivacyAgreement"
import Popup from '../components/Popup';
// Components End

// images Start
import standard01 from "../images/home/standard01.webp"
import standard02 from "../images/home/standard02.webp"
import standard03 from "../images/home/standard03.webp"
import mockup01 from "../images/home/section05_mockup01.webp"
import mockup02 from "../images/home/section05_mockup02.webp"
import mockup03 from "../images/home/section05_mockup03.webp"
import textWrap from "../images/home/section05_textwrap01.webp"
import section08_01 from "../images/home/section08_01.jpg"
// images End

// CSS Start
import 'swiper/css/pagination';
// CSS End

const MainPage = ({ t }) => {

   const windowWidth = document.body.clientWidth;
   const isMobile = windowWidth < 768

   const [privacyShow, setPrivacyShow] = useState(false);
   const openPrivacyAgreement = () => {
      setPrivacyShow(true)
   }
   const closePrivacy = () => {
      setPrivacyShow(false)
   }

   // 폼 데이터 전송 Start
   const [formData, setFormData] = useState({
      lang: t("lang"),
      name: '',
      phone: '',
      email: '',
      company: '',
      content: '',
      agreement: false,
   });
   const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      if (type === 'checkbox') {
         setFormData({
            ...formData,
            [name]: checked,
         });
      } else {
         setFormData({
            ...formData,
            [name]: value,
         });
      }
   };
   const handleSubmit = (e) => {
      e.preventDefault();
      if (formData.agreement === 0) {
         alert('개인정보 수집 및 이용에 동의해야 합니다.');
         return;
      }

      axios.post(`${process.env.REACT_APP_API_URL}/api/inquiries`, formData)
         .then(response => {
            if (response.status !== false) {
               alert('문의가 등록되었습니다.');
               window.location.reload();
            } else {
               alert('문의 등록에 실패했습니다.');
            }
         })
         .catch(error => {
            console.error('There was an error!', error);
            alert('문의 등록에 실패했습니다.');
         });
   };
   // 폼 데이터 전송 End

   const [bannerData, setBannerData] = useState(null);
   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/banners?lang=${t("lang")}`)
            setBannerData(response.data.data)
         } catch (error) {
            console.error(error)
         }
      }

      fetchData();
   }, [t])

   const [curriculumData01, setCurriculumsData01] = useState();
   const [curriculumBtn01, setCurriculumBtn01] = useState(3);
   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/curriculums?lang=${t("lang")}&course=${curriculumBtn01}&perPage=12`)
            setCurriculumsData01(response.data.data)
         } catch (error) {
            console.error(error)
         }
      }

      fetchData();
   }, [t, curriculumBtn01])

   const curriculumBtn01Click = (value) => {
      setCurriculumBtn01(value);
   }
   // 
   const [curriculumData02, setCurriculumsData02] = useState();
   const [curriculumBtn02, setCurriculumBtn02] = useState(1);
   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/curriculums?lang=${t("lang")}&course=${curriculumBtn02}&perPage=12`)
            setCurriculumsData02(response.data.data)
         } catch (error) {
            console.error(error)
         }
      }

      fetchData();
   }, [t, curriculumBtn02])

   const curriculumBtn02Click = (value) => {
      setCurriculumBtn02(value);
   }

   const [mathLettersData, setMathLettersData] = useState();
   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/letters?lang=${t("lang")}&perPage=12&exposure=1`)
            setMathLettersData(response.data.data)
         } catch (error) {
            console.error(error)
         }
      }

      fetchData();
   }, [t])


   return (
      <main className='main_page'>
         <Popup />
         <section>
            <Swiper className='mb_wrap'>
               {bannerData ? (
                  bannerData.map((banner, index) => (
                     isMobile ? (
                        <SwiperSlide
                           className='mb'
                           key={index}
                        >
                           <div className='banner_text_wrap'>
                              <h2 className='main_tit xl:text-106 text-32 font-bold xl:mb-32 mb-24'>
                                 <span className='point text_G'>G</span>
                                 <span className='text'>eneral&nbsp;</span>
                                 <span className='point text_O'>O</span>
                                 <span className='text'>nologic&nbsp;</span>
                                 <span className='point text_S'>S</span>
                                 <span className='text'>oft</span>
                              </h2>
                              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(banner.content) }} className="txt text-center xl:leading-36 leading-22 xl:text-24 text-14"></p>
                           </div>
                           {banner.mb_file.mimeType.startsWith('video') ? (
                              <video className='mb_bg_video' muted autoPlay loop playsInline>
                                 <source src={process.env.REACT_APP_API_URL + banner.mb_file.path} type="video/mp4"></source>
                              </video>
                           ) : (
                              <img className='mb_bg_img' src={process.env.REACT_APP_API_URL + banner.mb_file.path} alt='banner' />
                           )}
                        </SwiperSlide>
                     ) : (
                        <SwiperSlide
                           className='mb'
                           key={index}
                        >
                           <div className='banner_text_wrap'>
                              <h2 className='main_tit xl:text-106 text-32 font-bold xl:mb-32 mb-24'>
                                 <span className='point text_G'>G</span>
                                 <span className='text'>eneral&nbsp;</span>
                                 <span className='point text_O'>O</span>
                                 <span className='text'>nologic&nbsp;</span>
                                 <span className='point text_S'>S</span>
                                 <span className='text'>oft</span>
                              </h2>
                              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(banner.content) }} className="txt text-center xl:leading-36 leading-22 xl:text-24 text-14"></p>
                           </div>
                           {banner.pc_file.mimeType.startsWith('video') ? (
                              <video className='mb_bg_video' muted autoPlay loop playsInline>
                                 <source src={process.env.REACT_APP_API_URL + banner.pc_file.path} type="video/mp4"></source>
                              </video>
                           ) : (
                              <img className='mb_bg_img' src={process.env.REACT_APP_API_URL + banner.pc_file.path} alt='banner' />
                           )}
                        </SwiperSlide>
                     )
                  ))
               ) : (
                  <div className='hide_txt'>Loading ...</div>
               )}
            </Swiper>
            <div>

            </div>
         </section>
         <section className='section01 content type01'>
            <div className='txt_wrap'>
               <h3 className='txt xl:text-88 text-40 font-bold text-center text-white'>{t("main_section01_01")}</h3>
               <h3 className='txt xl:text-88 text-40 font-bold point text-center text-white'>{t("main_section01_02")}</h3>
               <h3 className='txt xl:text-88 text-40 font-bold text-center text-white'>{t("main_section01_03")}</h3>
            </div>
         </section>
         <section className='section02 content type01'>
            <div className='txt_wrap'>
               <h3 className='txt xl:text-88 text-40 font-bold text-center text-white'>{t("main_section01_01")}</h3>
               <h3 className='txt xl:text-88 text-40 font-bold point text-center text-white'>{t("main_section02_02")}</h3>
               <h3 className='txt xl:text-88 text-40 font-bold text-center text-white'>{t("main_section02_03")}</h3>
            </div>
         </section>
         <section className='section03 content type01'>
            <div className='txt_wrap'>
               <h3 className='txt xl:text-88 text-40 font-bold text-center text-white'>{t("main_section01_01")}</h3>
               <h3 className='txt xl:text-88 text-40 font-bold point text-center text-white'>{t("main_section03_02")}</h3>
               <h3 className='txt xl:text-88 text-40 font-bold text-center text-white'>{t("main_section03_03")}</h3>
            </div>
         </section>
         <section className='section04 content type01'>
            <h3 className='tit text-center font-bold xl:text-80 text-40'>{t("main_section04_01")}</h3>
            <ul className='item_wrap wrap xl:mt-40 mt-32'>
               <li className='item type01'>
                  <p className='num text-center xl:text-24 text-16 font-bold'>01</p>
                  <div className='img_wrap'>
                     <img className='img size01' src={standard01} alt='' />
                  </div>
                  <div>
                     <h4 className='txt text-center xl:text-24 text-16 font-bold xl:mb-24 mb-16'>{t("main_section04_02")}</h4>
                     <p className='txt text-center xl:leading-26 leading-20 xl:text-17 text-13'>{t("main_section04_03")}</p>
                  </div>
                  <Link to="/math/essence" className='btn_link xl:mt-40 mt-32'>
                     <i className='icon xi-arrow-right xl:text-15 text-white'></i>
                  </Link>
               </li>
               <li className='item type02 xl:mb-13'>
                  <p className='num text-center xl:text-24 text-16 font-bold'>02</p>
                  <div className='img_wrap'>
                     <img className='img size03' src={standard02} alt='' />
                  </div>
                  <div>
                     <h4 className='text-center xl:text-24 text-16 font-bold xl:mb-24 mb-16'>{t("main_section04_04")}</h4>
                     <p className='text-center xl:leading-26 leading-20 xl:text-17 text-13'>{t("main_section04_05")}</p>
                  </div>
                  <Link to="/math/ideal" className='btn_link xl:mt-40 mt-32'>
                     <i className='icon xi-arrow-right xl:text-15 text-white'></i>
                  </Link>
               </li>
               <li className='item type01'>
                  <p className='num text-center xl:text-24 text-16 font-bold'>03</p>
                  <div className='img_wrap'>
                     <img className='img size02' src={standard03} alt='' />
                  </div>
                  <div>
                     <h4 className='txt text-center xl:text-24 text-16 font-bold xl:mb-24 mb-16'>{t("main_section04_06")}</h4>
                     <p className='txt text-center xl:leading-26 leading-20 xl:text-17 text-13'>{t("main_section04_07")}</p>
                  </div>
                  <Link to="/math/general" className='btn_link xl:mt-40 mt-32'>
                     <i className='icon xi-arrow-right xl:text-15 text-white'></i>
                  </Link>
               </li>
            </ul>
         </section>
         <section className='section05 content type01 xl:pt-0 pt-120'>
            <Swiper
               className='swiper01 wrap'
               slidesPerView={1}
               pagination={true}
               modules={[Pagination]}
            >
               <SwiperSlide>
                  <h4 className='mb_tit text-40 font-bold mb-40'>
                     <span className='point'>{t("main_section05_01")}</span>{t("main_section05_01_01")}
                  </h4>
                  <div className='slide_wrap'>
                     <div>
                        <h4 className='tit xl:text-56 text-40 font-bold xl:mb-56 mb-40'>
                           <span className='point'>{t("main_section05_01")}</span>{t("main_section05_01_01")}
                        </h4>
                        <div className='txt_img_wrap xl:mb-36 mb-32'>
                           <h3 className='xl:text-64 text-32 text-white font-bold'>{t("main_section05_02")}</h3>
                           <img className='img' src={textWrap} alt='' />
                        </div>
                        <p className='xl:leading-28 leading-22 xl:text-18 text-14 text-white xl:mb-80 mb-48'>
                           <Trans i18nKey="main_section05_03" components={{ br: <br /> }} />
                        </p>
                        <Link to="/education/it-math" className='btn_link'>
                           <p className='xl:text-18 text-14 text-white'>{t("more_view")}</p>
                           <i className='xi-arrow-right text-white'></i>
                        </Link>
                     </div>
                  </div>
                  <img className='mockup_img' src={mockup01} alt='' />
               </SwiperSlide>
               <SwiperSlide>
                  <h4 className='mb_tit text-40 font-bold mb-40'>
                     <span className='point'>{t("main_section05_01")}</span>{t("main_section05_01_01")}
                  </h4>
                  <div className='slide_wrap'>
                     <div>
                        <h4 className='tit xl:text-56 text-40 font-bold xl:mb-56 mb-40'>
                           <span className='point'>{t("main_section05_01")}</span>{t("main_section05_01_01")}
                        </h4>
                        <div className='txt_img_wrap xl:mb-36 mb-32'>
                           <h3 className='xl:text-64 text-32 text-white font-bold'>{t("main_section05_04")}</h3>
                           <img className='img' src={textWrap} alt='' />
                        </div>
                        <p className='xl:leading-28 leading-22 xl:text-18 text-14 text-white xl:mb-80 mb-48'>
                           <Trans i18nKey="main_section05_05" components={{ br: <br /> }} />
                        </p>
                        <Link to="/education/ar-math" className='btn_link'>
                           <p className='xl:text-18 text-14 text-white'>{t("more_view")}</p>
                           <i className='xi-arrow-right text-white'></i>
                        </Link>
                     </div>
                  </div>
                  <img className='mockup_img' src={mockup02} alt='' />
               </SwiperSlide>
               <SwiperSlide>
                  <h4 className='mb_tit text-40 font-bold mb-40'>
                     <span className='point'>{t("main_section05_01")}</span>{t("main_section05_01_01")}
                  </h4>
                  <div className='slide_wrap'>
                     <div>
                        <h4 className='tit xl:text-56 text-40 font-bold xl:mb-56 mb-40'>
                           <span className='point'>{t("main_section05_01")}</span>{t("main_section05_01_01")}
                        </h4>
                        <div className='txt_img_wrap xl:mb-36 mb-32'>
                           <h3 className='xl:text-64 text-32 text-white font-bold'>{t("main_section05_06")}</h3>
                           <img className='img' src={textWrap} alt='' />
                        </div>
                        <p className='xl:leading-28 leading-22 xl:text-18 text-14 text-white xl:mb-80 mb-48'>
                           <Trans i18nKey="main_section05_07" components={{ br: <br /> }} />
                        </p>
                        <Link to="/education/dt-math" className='btn_link'>
                           <p className='xl:text-18 text-14 text-white'>{t("more_view")}</p>
                           <i className='xi-arrow-right text-white'></i>
                        </Link>
                     </div>
                  </div>
                  <img className='mockup_img' src={mockup03} alt='' />
               </SwiperSlide>
            </Swiper>
         </section>
         <section className='section06 wrap xl:pt-170 pt-80 xl:pb-170 pb-80'>
            <div className='tit_wrap type01 type01-1'>
               <h3 className='tit xl:text-56 text-40 font-bold'>
                  <span className='point'>{t("main_section06_01")}</span>{t("main_section06_02")}
               </h3>
               <Link className='btn_link' to="/curriculum-general/elementary">
                  <i className='xi-arrow-right'></i>
               </Link>
            </div>
            <ul className='link_wrap type01 xl:mt-24 mt-16'>
               <li>
                  <button onClick={() => curriculumBtn01Click(3)} className={`btn_link ${curriculumBtn01 === 3 ? "on" : ""}`}>
                     <p className='xl:text-16 text-12 font-medium'>{t("main_section06_03")}</p>
                  </button>
               </li>
               <li>
                  <button onClick={() => curriculumBtn01Click(4)} className={`btn_link ${curriculumBtn01 === 4 ? "on" : ""}`}>
                     <p className='xl:text-16 text-12 font-medium'>{t("main_section06_04")}</p>
                  </button>
               </li>
            </ul>
            <Swiper
               className='swiper02 swiper type02 xl:mt-40 mt-32'
               slidesPerView={"auto"}
               spaceBetween={16}
               freeMode={true}
               modules={[FreeMode]}
               breakpoints={{
                  1280: {
                     spaceBetween: 40
                  }
               }}
            >
               {
                  curriculumData01 && curriculumData01.length > 0 ? (
                     curriculumData01.map((curriculum, index) => (
                        curriculum.exposure === 1 ? (
                           <SwiperSlide className='slide' key={index}>
                              <Link to={`/curriculum/${curriculum.id}`} className='btn_link type-search-wrap'>
                                 <img className="thumbnail" src={process.env.REACT_APP_API_URL + curriculum.image.path} alt='' />
                                 <div className="icon_search_wrap type01">
                                    <i className="xi-search text-44 text-white"></i>
                                 </div>
                              </Link>
                              <p className='des xl:text-16 text-12 xl:leading-26 leading-20 xl:mt-26 mt-16'>
                                 {curriculum.title}
                              </p>
                           </SwiperSlide>
                        ) : (
                           ''
                        )
                     ))
                  ) : (
                     <SwiperSlide className='slide' key="0">게시글이 없습니다.</SwiperSlide>
                  )
               }
            </Swiper>
         </section>
         <section className='section07 xl:pt-170 pt-80 xl:pb-170 pb-80'>
            <div className='wrap'>
               <div className='tit_wrap type01 type01-1'>
                  <h3 className='tit xl:text-56 text-40 font-bold'>
                     <span className='point'>{t("main_section07_01")}</span>{t("main_section07_02")}
                  </h3>
                  <Link className='btn_link' to="/curriculum-kids/infant">
                     <i className='xi-arrow-right'></i>
                  </Link>
               </div>
               <ul className='link_wrap type01 xl:mt-24 mt-16'>
                  <li>
                     <button onClick={() => curriculumBtn02Click(1)} className={`btn_link ${curriculumBtn02 === 1 ? "on" : ""}`}>
                        <p className='xl:text-16 text-12 font-medium'>{t("main_section07_03")}</p>
                     </button>
                  </li>
                  <li>
                     <button onClick={() => curriculumBtn02Click(2)} className={`btn_link ${curriculumBtn02 === 2 ? "on" : ""}`}>
                        <p className='xl:text-16 text-12 font-medium'>{t("main_section07_04")}</p>
                     </button>
                  </li>
               </ul>
               <Swiper
                  className='swiper03 swiper type02 xl:mt-40 mt-32'
                  slidesPerView={"auto"}
                  spaceBetween={16}
                  freeMode={true}
                  modules={[FreeMode]}
                  breakpoints={{
                     1280: {
                        spaceBetween: 40
                     }
                  }}
               >
                  {
                     curriculumData02 && curriculumData02.length > 0 ? (
                        curriculumData02.map((curriculum, index) => (
                           curriculum.exposure === 1 ? (
                              <SwiperSlide className='slide' key={index}>
                                 <Link to={`/curriculum/${curriculum.id}`} className='btn_link type-search-wrap'>
                                    <img className="thumbnail" src={process.env.REACT_APP_API_URL + curriculum.image.path} alt='' />
                                    <div className="icon_search_wrap type01">
                                       <i className="xi-search text-44 text-white"></i>
                                    </div>
                                 </Link>
                                 <p className='des xl:text-16 text-12 xl:leading-26 leading-20 xl:mt-26 mt-16'>
                                    {curriculum.title}
                                 </p>
                              </SwiperSlide>
                           ) : (
                              ''
                           )
                        ))
                     ) : (
                        <SwiperSlide key="0" className='text-white'>게시글이 없습니다.</SwiperSlide>
                     )
                  }
               </Swiper>
            </div>
         </section>
         <section className='section08 xl:pt-195 pt-80 xl:pb-110 pb-80'>
            <div className="container">
               <div className="text_wrap xl:mb-0 mb-110">
                  <h3 className='tit xl:text-56 text-40 xl:mb-40 mb-32 font-bold'>
                     <span className='point'>L</span>iberal Arts Program
                  </h3>
                  <p className='txt xl:text-18 text-14 xl:leading-28 leading-22'>
                     {t("main_section08_01")}
                  </p>
                  <Link to='/arts/overview' className='btn_moreview xl:text-16 text-13 xl:mt-40 mt-24'>
                     <p>더보기</p>
                     <i className='xi-arrow-right'></i>
                  </Link>
               </div>
               <div className="content_wrap">
                  <div className="scroll_content">
                     <div className='item type01 xl:mb-56 mb-40'>
                        <img className='xl:mb-32 mb-24' src={section08_01} alt='' />
                        <h4 className='xl:text-28 text-18 xl:leading-28 leading-22 font-bold'>{t("main_section08_02")}</h4>
                        <p className='xl:text-18 text-14 xl:leading-28 leading-22 xl:mt-16 mt-16'>
                           {t("main_section08_03")}
                        </p>
                     </div>
                     <div className='item type02  xl:mb-56 mb-40'>
                        <img className='xl:mb-32 mb-24' src={section08_01} alt='' />
                        <h4 className='xl:text-28 text-18 xl:leading-28 leading-22 font-bold'>{t("main_section08_04")}</h4>
                        <p className='xl:text-18 text-14 xl:leading-28 leading-22 xl:mt-16 mt-16'>
                           {t("main_section08_05")}
                        </p>
                     </div>
                     <div className='item type01'>
                        <img className='xl:mb-32 mb-24' src={section08_01} alt='' />
                        <h4 className='xl:text-28 text-18 xl:leading-28 leading-22 font-bold'>{t("main_section08_06")}</h4>
                        <p className='xl:text-18 text-14 xl:leading-28 leading-22 xl:mt-16 mt-16'>
                           {t("main_section08_07")}
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className='section09 xl:pt-140 pt-80 xl:pb-140 pb-80'>
            <div className='tit_wrap type01 xl:mb-64 mb-40'>
               <h3 className='tit xl:leading-56 leading-32 leading-52 xl:text-56 text-40 font-bold'>
                  <span className='point'>{t("main_section09_01")}</span>{t("main_section09_02")} <br className='mo_only' />{t("main_section09_03")}
               </h3>
               <Link to='/blueberry-espresso/math-letters' className='btn_link'>
                  <i className='xi-arrow-right xl:text-24 text-16'></i>
               </Link>
            </div>
            <Swiper
               className='swiper04 wrap'
               slidesPerView={1}
               spaceBetween={16}
               modules={[Pagination]}
               pagination={true}
               breakpoints={{
                  1280: {
                     slidesPerView: 2,
                     spaceBetween: 56,
                  },
               }}
            >
               {mathLettersData ? (
                  mathLettersData.map((mathLetters, index) => (
                     <SwiperSlide className='slide' key={index}>
                        <div className='slide_item'>
                           <div className='iframe_video_wrap'>
                              <iframe
                                 width="100%"
                                 height="auto"
                                 src={`https://www.youtube.com/embed/${mathLetters.youtube}`}
                                 title="YouTube video player"
                                 allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                 referrerPolicy="strict-origin-when-cross-origin"
                                 allowFullScreen
                              >
                              </iframe>
                           </div>
                           <div className='xl:mt-32 mt-24'>
                              <h4 className='xl:text-24 text-16 font-semibold'>{mathLetters.title}</h4>
                              <p
                                 dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(mathLetters.content, {
                                       ALLOWED_ATTR: []
                                    })
                                 }}
                                 className="xl:text-18 text-14 xl:leading-28 leading-22 xl:mt-24 mt-16"
                              >
                              </p>
                           </div>
                        </div>
                     </SwiperSlide>
                  ))
               ) : (
                  <div className='loading'>Loading ...</div>
               )}
            </Swiper>
         </section>
         <section className='section10 contact_us'>
            <div className='item01 item'>
               <div>
                  <p className='xl:text-17 text-13 font-light'>General Onologic Soft</p>
                  <h3 className='tit xl:text-56 text-40 font-bold xl:mt-18 mt-16'>
                     <span className="point">문</span>의하기
                  </h3>
                  <p className='xl:leading-26 leading-20 xl:text-17 text-13 font-light xl:mt-34 mt-24'>
                     GOS에 궁금한 점을 남겨주세요.<br className="pc_only" />
                     저희 회사는 언제나 고객의 목소리에 귀 기울이고 있습니다.
                  </p>
                  <div className="xl:mt-40 mt-32">
                     <div className="icon_wrap xl:mb-16 mb-8">
                        <i className="icon xi-mail-o xl:text-24 text-16"></i>
                        <p className='xl:text-17 text-13 font-medium'>davidann819@gmail.com</p>
                     </div>
                     <div className="icon_wrap">
                        <i className="icon xi-maker xl:text-24 text-16"></i>
                        <p className='xl:text-17 text-13 font-medium'>101 Metro Drive, Suite 365, San Jose, CA 95110</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='item02 item'>
               <form onSubmit={handleSubmit} className="inquiry_from">
                  <fieldset className="inquiry_fieldset">
                     <legend className="hide">contact us</legend>
                     <div className="input_box_wrap xl:mb-32 mb-24">
                        <div className="dep md:mb-0 mb-24">
                           <label htmlFor="name" className="xl:text-18 text-14 font-bold xl:mb-16 mb-8">성함 <span className="point">*</span></label>
                           <input
                              className="input_box xl:text-18 text-14"
                              type="text"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              placeholder="성함을 입력하세요."
                              required
                           />
                        </div>
                        <div className="dep md:mb-0 mb-24">
                           <label htmlFor="contact" className="xl:text-18 text-14 font-bold xl:mb-16 mb-8">연락처 <span className="point">*</span></label>
                           <input
                              className="input_box xl:text-18 text-14"
                              type="tel"
                              id="contact"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                              placeholder="연락처를 입력하세요."
                              required
                           />
                        </div>
                     </div>
                     <div className="input_box_wrap xl:mb-32 mb-24">
                        <div className="dep md:mb-0 mb-24">
                           <label htmlFor="company" className="xl:text-18 text-14 font-bold xl:mb-16 mb-8">회사명</label>
                           <input
                              className="input_box xl:text-18 text-14"
                              type="company"
                              id="company"
                              name="company"
                              value={formData.company}
                              onChange={handleChange}
                              placeholder="회사명을 입력하세요."
                           />
                        </div>
                        <div className="dep md:mb-0 mb-24">
                           <label htmlFor="email" className="xl:text-18 text-14 font-bold xl:mb-16 mb-8">이메일 <span className="point">*</span></label>
                           <input
                              className="input_box xl:text-18 text-14"
                              type="email"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              placeholder="이메일을 입력하세요."
                              required
                           />
                        </div>
                     </div>
                     <div className="input_box_wrap">
                        <div className="dep md:mb-0 mb-24">
                           <label htmlFor="inquiry" className="xl:text-18 text-14 font-bold xl:mb-16 mb-8">문의내용</label>
                           <textarea
                              className="input_box xl:text-18 text-14"
                              type="text"
                              id="inquiry"
                              name="content"
                              value={formData.content}
                              onChange={handleChange}
                              placeholder="문의내용을 기재해주세요."
                           />
                        </div>
                     </div>
                     <div className="input_box_wrap agreement xl:mt-32 mt-24">
                        <div className="privacy_agree_wrap">
                           <input
                              id="privacy_agree"
                              name="agreement"
                              value="1"
                              checked={formData.agreement}
                              onChange={handleChange}
                              required
                              type="checkbox"
                           />
                           <label className='xl:text-17 text-13' htmlFor="privacy_agree">개인정보 수집 및 이용에 대한 동의 (필수)</label>
                           <button onClick={() => { openPrivacyAgreement() }} className="view_all xl:text-17 text-13" type="button">전문보기</button>
                        </div>
                     </div>
                     <button className="xl:mt-40 mt-32 btn type01 font-bold" type="submit">문의 등록</button>
                  </fieldset>
               </form>
            </div>
         </section>
         <PrivacyAgreement
            show={privacyShow}
            close={closePrivacy}
         />
      </main >
   )
}

export default withTranslation()(MainPage)