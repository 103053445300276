import { Link, useLocation } from "react-router-dom";

export default function SubPageBanner({ title, category, navDep_01, navDep_02 }) {

   const location = useLocation();
   const pathName = location.pathname;

   const navItemsConfig = {
      "/company": [
         { path: "/company/values", gnbItem: "핵심가치" },
         { path: "/company/vision", gnbItem: "미션 비전" },
         { path: "/company/contact", gnbItem: "문의하기" }
      ],
      "/math": [
         { path: "/math/essence", gnbItem: "수학의 본질" },
         { path: "/math/ideal", gnbItem: "바람직한 수학 교육" },
         { path: "/math/general", gnbItem: "제너럴매스" }
      ],
      "/education": [
         { path: "/education/it-math", gnbItem: "IT 기반 수학 교육 서비스" },
         { path: "/education/ar-math", gnbItem: "AR 기반 수학 교육 서비스" },
         { path: "/education/dt-math", gnbItem: "DT 기반 수학 교육 서비스" }
      ],
      "/curriculum-general": [
         { path: "/curriculum-general/elementary", gnbItem: "초등 교육 과정" },
         { path: "/curriculum-general/secondary", gnbItem: "중고등 교육과정" }
      ],
      "/curriculum-kids": [
         { path: "/curriculum-kids/infant", gnbItem: "유아부 교육 과정" },
         { path: "/curriculum-kids/preschool", gnbItem: "유치부 교육 과정" }
      ],
      "/arts": [
         { path: "/arts/overview", gnbItem: "프로그램 개요" },
         { path: "/arts/curriculum", gnbItem: "교육 커리큘럼" },
         { path: "/arts/preliminary", gnbItem: "예비 진단 프로그램" },
         { path: "/arts/membership", gnbItem: "회원 가입" }
      ],
      "/blueberry-espresso": [
         { path: "/blueberry-espresso/math-letters", gnbItem: "수학편지" },
         { path: "/blueberry-espresso/math-farm", gnbItem: "수학농장" },
         { path: "/blueberry-espresso/math-ribbon", gnbItem: "수학의 띠 운동" },
         { path: "/blueberry-espresso/news", gnbItem: "회사소식" }
      ]
   };

   const navItems = Object.keys(navItemsConfig).find(key => pathName.startsWith(key))
      ? navItemsConfig[Object.keys(navItemsConfig).find(key => pathName.startsWith(key))]
      : [];

   const pathSegments = pathName.split('/').filter(segment => segment);
   const pathDepth = pathSegments.length;

   const gnbItemClick = () => {
      const mbGnb = document.querySelector(".sub_page .mb_wrap>.wrap>.gnb");
      const mbGnbIconArrow = document.querySelectorAll(".sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap .item.top .icon_arrow")
      if (mbGnb.classList.contains("on")) {
         mbGnb.classList.remove("on")
         mbGnbIconArrow.forEach((element) => {
            element.classList.remove("rotate-180")
         })
      } else {
         mbGnb.classList.add("on")
         mbGnbIconArrow.forEach((element) => {
            element.classList.add("rotate-180")
         })
      }
   }

   return (
      <div className="mb_wrap xl:mb-80 mb-48">
         <div className="wrap">
            <div className="top_item">
               <p className="xl:text-20 text-13 font-semibold">{category}</p>
               <ul className="nav">
                  <li><i className="xi-home-o xl:text-16 text-12"></i></li>
                  <li><i className="xi-angle-right-min xl:text-17 text-13 arrow"></i></li>
                  <li className="xl:text-16 text-12 font-bold">{navDep_01}</li>
                  {pathDepth >= 2 && (
                     <>
                        <li><i className="xi-angle-right-min xl:text-17 text-13 arrow"></i></li>
                        <li className="xl:text-16 text-12 font-bold">{navDep_02}</li>
                     </>
                  )}
               </ul>
            </div>
            <h2 className="tit xl:text-56 text-40 font-bold text-white xl:mt-24 mt-16">{title}</h2>
            <div className="gnb wrap">
               <ul className="pc_only gnb_item_wrap">
                  {navItems.map((item, index) => (
                     <li key={index} className="item">
                        <Link className={`gnb_item ${pathName === item.path ? "on" : ""}`} to={item.path}>{item.gnbItem}</Link>
                     </li>
                  ))}
               </ul>
               <ul className="mo_only gnb_item_wrap">
                  {navItems.map((item, index) => (
                     <li key={index} className={`item ${pathName === item.path ? "top" : ""}`}>
                        <Link onClick={() => { gnbItemClick() }} className={`gnb_item ${pathName === item.path ? "on" : ""}`} to={item.path}>{item.gnbItem}</Link>
                        <i className="icon_arrow xi-angle-down"></i>
                     </li>
                  ))}
               </ul>
            </div>
         </div>
      </div>
   )
}
