import { Link } from "react-router-dom";

// Images Start
import Logo from "../images/common/logo_type02.webp"
// Images End

export default function Footer() {
   return (
      <footer id="footer" className="xl:pt-32 xl:pb-40">
         <div className="wrap">
            <ul className="ft_nav_wrap">
               <li className="ft_nav_item">
                  <h5 className="tit xl:text-15 font-bold xl:mb-16 mb-8">회사소개</h5>
                  <ul className="btn_link_wrap">
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">핵심가치</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">비전과 미션</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">문의하기</Link>
                     </li>
                  </ul>
               </li>
               <li className="ft_nav_item">
                  <h5 className="tit xl:text-15 font-bold xl:mb-16 mb-8">수학 교육의 표준</h5>
                  <ul className="btn_link_wrap">
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">수학의 본질</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">바람직한 수학 교육</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">제너럴매스</Link>
                     </li>
                  </ul>
               </li>
               <li className="ft_nav_item">
                  <h5 className="tit xl:text-15 font-bold xl:mb-16 mb-8">소비자 중심의 교육</h5>
                  <ul className="btn_link_wrap">
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">IT 기반 수학 교육 서비스</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">AR 기반 수학 교육 서비스</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">DT 기반 수학 교육 서비스</Link>
                     </li>
                  </ul>
               </li>
               <li className="ft_nav_item">
                  <h5 className="tit xl:text-15 font-bold xl:mb-16 mb-8">제너럴매스 144</h5>
                  <ul className="btn_link_wrap">
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">초등 교육 과정</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">중고등 교육 과정</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link"></Link>
                     </li>
                  </ul>
               </li>
               <li className="ft_nav_item">
                  <h5 className="tit xl:text-15 font-bold xl:mb-16 mb-8">키즈-프로기 144</h5>
                  <ul className="btn_link_wrap">
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">유아부 교육 과정</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">유치부 교육 과정</Link>
                     </li>
                  </ul>
               </li>
               <li className="ft_nav_item">
                  <h5 className="tit xl:text-15 font-bold xl:mb-16 mb-8">LiberalArtsOn Academy</h5>
                  <ul className="btn_link_wrap">
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">프로그램 개요</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">교육 커리큘럼</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">예비 진단 프로그램</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">회원 가입</Link>
                     </li>
                  </ul>
               </li>
               <li className="ft_nav_item">
                  <h5 className="tit xl:text-15 font-bold xl:mb-16 mb-8">블루베리 에스프레소</h5>
                  <ul className="btn_link_wrap">
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">수학편지</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">수학농장</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">수학의 띠 운동</Link>
                     </li>
                     <li className="btn_link_li">
                        <Link to="" className="xl:text-14 text-12 btn_link">회사소식</Link>
                     </li>
                  </ul>
               </li>
            </ul>
            <div className="ft_bottom_wrap xl:pt-24 xl:mt-50">
               <img className="logo" src={Logo} alt="logo" />
               <div className="xl:mt-0 mt-24">
                  <div className="info_wrap xl:mb-16 mb-24">
                     <p className="txt xl:text-14 text-12">General Onologic Soft Corp.</p>
                     <p className="txt xl:text-14 text-12 xl:mt-0 mt-10">101 Metro Drive, Suite 365, San Jose, CA 95110</p>
                     <p className="txt xl:text-14 text-12 xl:mt-0 mt-10">E-Mail : davidann819@gmail.com</p>
                  </div>
                  <small className=" xl:text-14 text-12 font-medium">Copyright 2024 General Onologic Soft Corp. All rights reserved.</small>
               </div>
            </div>
         </div>
      </footer>
   )
}