// Components Start
import SubPageBanner from "../../components/SubPageBanner";
// Components End

// Images Start
import numItem01 from "../../images/math-education-standards/num_item01.webp"
import numItem02 from "../../images/math-education-standards/num_item02.webp"
import numItem03 from "../../images/math-education-standards/num_item03.webp"
// Images End

export default function MathEssenceIntro() {
   return (
      <div className="math_essence_intro math_education_standards sub_page">
         <SubPageBanner
            category="수학 교육의 표준"
            title="창의력의 씨앗을 성장시킵니다"
            navDep_01="수학 교육의 표준"
            navDep_02="수학의 본질"
         />
         <section className="section01 wrap xl:mb-120 mb-40">
            <h3 className="tit xl:text-40 text-22 xl:leading-56 leading-32 font-bold">
               수학은 자연의 비밀을<br />
               발견하는 학문입니다.
            </h3>
            <p className="txt xl:text-20 text-13 xl:leading-32 leading-20 xl:mt-0 mt-20">
               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
               et dolore magna aliqua.
               Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
               aliquip ex ea commodo consequat.
            </p>
         </section>
         <section className="section02">
            <div className="text_wrap">
               <h3 className="xl:text-32 text-20 xl:leading-32 leading-28 font-bold text-center text-white xl:mb-40 mb-24">수학의 본질과 관련된 내용을 입력해주세요.</h3>
               <p className="xl:text-20 text-13 xl:leading-32 leading-20 text-center text-white">
                  “과연 잘할 수 있을까?”, “지금 시작하기에는 너무 늦은 것 아닐까?” 새로운 시작을 두고 고민부터 앞서는 사람들에게 타샤 튜더는 말한다.<br className="pc_only"/>
                  “인생은 짧지 않나요? 하고 싶은 일이 있으면 하는 게 좋지요.” 동화책과 그림을 그려 받은 인세를 모아 구입한 버몬트주 산골의 30만 평의 황량한 대지를 환상적인 정원으로 가꿔낸<br className="pc_only"/>
                  타샤 튜더. 그가 오래도록 꿈꿔왔던 자신만의 정원을 가꾸기 시작한 건 쉰여섯이 되던 해였다.
               </p>
            </div>
         </section>
         <section className="section03 xl:pt-80 pt-40 pt-40 wrap">
            <ul>
               <li className="item xl:pb-40 xl:mb-40 mb-24 pb-24">
                  <img className="num_img" src={numItem01} alt="" />
                  <div>
                     <p className="tit leading-20 xl:text-20 text-14 font-bold xl:mb-16 mb-8">수학의 본질과 관련된 내용을 입력해주세요.</p>
                     <p className="txt xl:text-18 text-14 xl:leading-32 leading-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                  </div>
               </li>
               <li className="item xl:pb-40 xl:mb-40 mb-24 pb-24">
                  <img className="num_img" src={numItem02} alt="" />
                  <div>
                     <p className="tit leading-20 xl:text-20 text-14 font-bold xl:mb-16 mb-8">수학의 본질과 관련된 내용을 입력해주세요.</p>
                     <p className="txt xl:text-18 text-14 xl:leading-32 leading-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                  </div>
               </li>
               <li className="item xl:pb-40 pb-24">
                  <img className="num_img" src={numItem03} alt="" />
                  <div>
                     <p className="tit leading-20 xl:text-20 text-14 font-bold xl:mb-16 mb-8">수학의 본질과 관련된 내용을 입력해주세요.</p>
                     <p className="txt xl:text-18 text-14 xl:leading-32 leading-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                  </div>
               </li>
            </ul>
         </section>
      </div>
   )
}