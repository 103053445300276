// Components Start
import SubPageBanner from "../../components/SubPageBanner";
// Components End

// Images Start
import preliminary01 from "../../images/liberal-arts-program/preliminary01.webp"
import preliminary02 from "../../images/liberal-arts-program/preliminary02.webp"
import preliminary03 from "../../images/liberal-arts-program/preliminary03.webp"
import preliminary04 from "../../images/liberal-arts-program/preliminary04.webp"
// Images End

export default function PreliminaryDiagnosticProgram() {
   return (
      <div className="preliminary_diagnostic_program liberal_arts_program sub_page">
         <SubPageBanner
            category="Liberal Arts Program"
            title="맞춤형 교육으로 다가갑니다"
            navDep_01="Liberal Arts Program"
            navDep_02="예비 진단 프로그램"
         />
         <section className="section01 wrap">
            <h3 className="text-center xl:text-40 text-22 font-bold">예비 진단 프로그램</h3>
            <ul className="list_wrap xl:mt-48 mt-32">
               <li className="list xl:mb-24 mb-8">
                  <i className="icon xi-check-circle xl:text-26"></i>
                  <p className="xl:leading-28 leading-22 xl:text-22 text-13 font-bold">GOS 예비 진단 프로그램으로 적절한 레벨의 커리큘럼을 설정해드립니다.</p>
               </li>
               <li className="list">
                  <i className="icon xi-check-circle xl:text-26"></i>
                  <p className="xl:leading-28 leading-22 xl:text-22 text-13 font-bold">단순한 시험이 아닌, 학생의 수학능력에 대한 정확한 분석과 학습방법을 제시합니다.</p>
               </li>
            </ul>
            <ul className="item_wrap xl:mt-80 mt-42">
               <li className="item">
                  <div className="cir01">
                     <div className="cir02">
                        <img className="icon" src={preliminary01} alt="" />
                     </div>
                  </div>
                  <div>
                     <h4 className="text-center xl:leading-28 leading-22 xl:text-24 text-16 font-semibold xl:mt-32 mt-24">Program 1</h4>
                     <ul className="list_wrap02 xl:pl-32 xl:mt-24 mt-16">
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                     </ul>
                  </div>
               </li>
               <li className="item type_arrow">
                  <i className="icon xi-angle-right xl:text-32 text-20"></i>
               </li>
               <li className="item">
                  <div className="cir01">
                     <div className="cir02">
                        <img className="icon" src={preliminary02} alt="" />
                     </div>
                  </div>
                  <div>
                     <h4 className="text-center xl:leading-28 leading-22 xl:text-24 text-16 font-semibold xl:mt-32 mt-24">Program 1</h4>
                     <ul className="list_wrap02 xl:pl-32 xl:mt-24 mt-16">
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                     </ul>
                  </div>
               </li>
               <li className="pc_only item type_arrow">
                  <i className="icon xi-angle-right xl:text-32 text-20"></i>
               </li>
               <li className="item">
                  <div className="cir01">
                     <div className="cir02">
                        <img className="icon" src={preliminary03} alt="" />
                     </div>
                  </div>
                  <div>
                     <h4 className="text-center xl:leading-28 leading-22 xl:text-24 text-16 font-semibold xl:mt-32 mt-24">Program 1</h4>
                     <ul className="list_wrap02 xl:pl-32 xl:mt-24 mt-16">
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                     </ul>
                  </div>
               </li>
               <li className="item type_arrow">
                  <i className="icon xi-angle-right xl:text-32 text-20"></i>
               </li>
               <li className="item">
                  <div className="cir01">
                     <div className="cir02">
                        <img className="icon" src={preliminary04} alt="" />
                     </div>
                  </div>
                  <div>
                     <h4 className="text-center xl:leading-28 leading-22 xl:text-24 text-16 font-semibold xl:mt-32 mt-24">Program 1</h4>
                     <ul className="list_wrap02 xl:pl-32 xl:mt-24 mt-16">
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                        <li className="list type_dot xl:leading-32 leading-20 xl:text-20 text-13">내용을 입력해주세요.</li>
                     </ul>
                  </div>
               </li>
            </ul>
         </section>
      </div>
   )
}