import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import { withTranslation } from 'react-i18next';

// components Start
import SubPageBanner from "../../components/SubPageBanner";
import PageControl from "../../components/PageControl";
// components End

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

const News = ({ t }) => {

   const query = useQuery();
   const page = query.get('page');
   const search = query.get('search');

   const [data, setData] = useState();
   const [pageLink, setPageLink] = useState(page ? page : 1);

   const [searchTerm, setSearchTerm] = useState(search ? search : '');
   const [searchTermWrap, setSearchTermWrap] = useState(search ? search : '');
   const [activeIndex, setActiveIndex] = useState(page ? page : 1);

   const navigate = useNavigate();

   const handleChange = (event) => {
      setSearchTermWrap(event.target.value);
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      const regex = /^[a-zA-Z0-9가-힣\s]*$/;

      if (!regex.test(searchTermWrap)) {
         alert("영문[대소문자], 한글[자음+모음], 숫자로 입력해주세요");
         return;
      }

      setActiveIndex(1);
      setPageLink(1)
      setSearchTerm(searchTermWrap);
      navigate(`?page=${pageLink}&search=${searchTermWrap}`);
   };

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notices?lang=${t("lang")}&perPage=10&page=${pageLink}&search=${searchTerm}`);
            setData(response.data);
            if (response.data.data.state === false) {
               alert("일시적인 오류가 발생했습니다.")
            }
         } catch (error) {
            console.log(error);
         }
      };

      fetchData();
      navigate(`?page=${pageLink}&search=${searchTerm}`);
   }, [t, searchTerm, pageLink, page, search, navigate]);

   return (
      <div className="news blueberry_espresso type_notice sub_page">
         <SubPageBanner
            category="블루베리 에스프레소"
            title="블루베리 에스프레소 내용을 압력해주세요."
            navDep_01="블루베리 에스프레소"
            navDep_02="회사소식"
         />
         <div className="wrap">
            <div className="top_nav type_01">
               <h3 className="tit text-center xl:text-44 text-22 font-bold xl:mb-80 mb-24">회사소식</h3>
               <div className="top_item_wrap">
                  <p className="text_count xl:text-18 xl:mt-0 mt-24">총 {data ? (<strong className="point">{data.meta.total}</strong>) : (<strong className="point">0</strong>)}개의 게시물</p>
                  <form className="search_from" onSubmit={handleSubmit}>
                     <fieldset>
                        <legend className="hide">검색</legend>
                        <div className="search_box">
                           <input
                              id="search"
                              type="search"
                              name="search"
                              placeholder="검색어를 입력하세요"
                              value={searchTermWrap}
                              onChange={handleChange}
                           />
                           <button onClick={handleSubmit} type="button">
                              <i className="xi-search icon_search" />
                           </button>
                        </div>
                     </fieldset>
                  </form>
               </div>
            </div>
            <ul className="list_wrap">
               {
                  data ? (
                     data.meta.total === 0 ? (
                        <li className="item noitem txt_align_center">게시글이 없습니다.</li>
                     ) : (
                        data.data.map((list, index) => (
                           <li key={index} className="item">
                              <Link to={`/blueberry-espresso/news/${list.id}?page=${pageLink}&search=${searchTerm}`} className="a_btn">
                                 <img src={process.env.REACT_APP_API_URL + list.image.path} className="thumbnail pc" alt="" />
                                 <div className="text_wrap">
                                    <h4 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(list.title) }} className="tit xl:text-20 text-16 font-semibold"></h4>
                                    <div
                                       dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(list.content, {
                                             ALLOWED_TAGS: ['p', 'div', 'a', 'span'],
                                             ALLOWED_ATTR: []
                                          })
                                       }}
                                       className="txt xl:text-16 text-13 xl:mt-24 mt-16"
                                    >
                                    </div>
                                 </div>
                                 <div className="icon_wrap type_01">
                                    <i className="icon xi-angle-right xl:text-17"></i>
                                 </div>
                              </Link>
                           </li>
                        ))
                     )
                  ) : (
                     <div className="icon_loading_wrap">
                        <i className="xi-spinner-2 xi-spin icon_loading"></i>
                     </div>
                  )
               }
            </ul>
            {data &&
               <PageControl
                  data={data}
                  setPageLink={setPageLink}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
               />}
         </div>
      </div >
   );
}

export default withTranslation()(News)