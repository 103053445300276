import SubPageBanner from "../../components/SubPageBanner"

// Images Start
import icon01 from "../../images/company/icon01.webp"
import CoreValues01 from "../../images/company/core_values01.webp"
// Images End

export default function CoreValues() {
   return (
      <div className="core_values company sub_page">
         <SubPageBanner
            category="회사소개"
            title="아름다운 수학의 세계에 환영합니다"
            navDep_01="회사소개"
            navDep_02="핵심 가치"
         />
         <section className="section01 wrap">
            <h3 className="text-center xl:text-40 text-22 font-bold">핵심가치</h3>
            <h4 className="tit text-center leading-32 xl:text-32 text-20 xl:mt-40 mt-24 xl:mb-80 mb-24">
               수학 교육의 <span className="point">글로벌 표준</span>을 만드는 <span className="point"><br className="mo_only"/>General Onologic Soft</span>
            </h4>
            <div className="item01">
               <img className="mv" src={CoreValues01} alt="" />
               <div className="xl:mt-0 mt-40">
                  <img className="icon xl:mb-24 mb-16 xl:ml-48" src={icon01} alt="" />
                  <h5 className="xl:text-32 text-20 xl:ml-48">
                     <strong className="xl:text-32 text-20 font-bold xl:leading-32 leading-20">GOS</strong><br />
                     (General Onologic Soft)는
                  </h5>
                  <div className="text_wrap xl:mt-32 mt-24">
                     <p className="xl:text-20 text-13 xl:leading-32 leading-20">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat.
                     </p>
                  </div>
               </div>
            </div>
         </section>
         <section className="section02 wrap">
            <div className="item01 item type01">
               <div className="left_content">
                  <p className="num xl:text-32 text-20 xl:leading-48 leading-28 xl:mr-22">01</p>
                  <h5 className="xl:text-32 text-20 xl:leading-48 leading-28 font-medium">
                     우리는<br />
                     <span className="point">새로운 패러다임의 개척자</span><br />
                     입니다
                  </h5>
               </div>
               <div className="right_content">
                  <div className="banner_item xl:pl-40 xl:mb-16 mb-8">
                     <div className="check_list_wrap xl:mb-32 mb-24">
                        <i className="icon xi-check-circle-o xl:text-18 text-14 xl:leading-28 leading-22"></i>
                        <p className="xl:text-18 text-14 xl:leading-28 leading-22">
                           그리스 자연주의와 유클리드의 요소는<br className="pc_only" />
                           수학 교육의 새로운 패러다임을 만들 수 있도록 영감을 주었습니다.
                        </p>
                     </div>
                     <div className="check_list_wrap">
                        <i className="icon xi-check-circle-o xl:text-18 text-14 xl:leading-28 leading-22"></i>
                        <p className="xl:text-18 text-14 xl:leading-28 leading-22">
                           이러한 비전을 바탕으로, 우리는 콘텐츠 제작의<br className="pc_only" />
                           선구자 역할을 하며 새로운 수학 교육을 실현할 수 있습니다.
                        </p>
                     </div>
                  </div>
                  <p className="xl:text-18 text-14 xl:leading-28 leading-22">
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                     Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                     ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                     cillum dolore eu fugiat nulla pariatur.

                     Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
               </div>
            </div>
            <div className="item02 item type01">
               <div className="left_content">
                  <p className="num xl:text-32 text-20 xl:leading-48 leading-28 xl:mr-22">02</p>
                  <h5 className="xl:text-32 text-20 xl:leading-48 leading-28 font-medium">
                     우리는<br />
                     <span className="point">새로운 프레임워크의 창조자</span><br />
                     입니다
                  </h5>
               </div>
               <div className="right_content">
                  <div className="banner_item xl:pl-40 xl:mb-16 mb-8">
                     <div className="check_list_wrap xl:mb-32 mb-24">
                        <i className="icon xi-check-circle-o xl:text-18 text-14 xl:leading-28 leading-22"></i>
                        <p className="xl:text-18 text-14 xl:leading-28 leading-22">
                           교육은 단순히 가르치는 것이 아닙니다.<br className="pc_only" />
                           아이들 안에서 타고난 재능을 이끌어내는 것입니다.
                        </p>
                     </div>
                     <div className="check_list_wrap">
                        <i className="icon xi-check-circle-o xl:text-18 text-14 xl:leading-28 leading-22"></i>
                        <p className="xl:text-18 text-14 xl:leading-28 leading-22">
                           학습자가 원하는 세상을 만들기 위해.<br className="pc_only" />
                           혁신적인 교육 플랫폼을 설계하고 개발했습니다.
                        </p>
                     </div>
                  </div>
                  <p className="xl:text-18 text-14 xl:leading-28 leading-22">
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                     Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                     ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                     cillum dolore eu fugiat nulla pariatur.

                     Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
               </div>
            </div>
            <div className="item03 item type01">
               <div className="left_content">
                  <p className="num xl:text-32 text-20 xl:leading-48 leading-28 xl:mr-22">03</p>
                  <h5 className="xl:text-32 text-20 xl:leading-48 leading-28 font-medium">
                     우리는<br />
                     <span className="point">모두가 꿈꿔온 혁신적인 교육</span><br />
                     입니다
                  </h5>
               </div>
               <div className="right_content">
                  <div className="banner_item xl:pl-40 xl:mb-16 mb-8">
                     <div className="check_list_wrap xl:mb-32 mb-24">
                        <i className="icon xi-check-circle-o xl:text-18 text-14 xl:leading-28 leading-22"></i>
                        <p className="xl:text-18 text-14 xl:leading-28 leading-22">
                           사람들은 책이 가르치고 배우는 데 필수적이라고 생각합니다.<br className="pc_only" />
                           그들은 책이 없는 교육을 상상할 수 없습니다.
                        </p>
                     </div>
                     <div className="check_list_wrap">
                        <i className="icon xi-check-circle-o xl:text-18 text-14 xl:leading-28 leading-22"></i>
                        <p className="xl:text-18 text-14 xl:leading-28 leading-22">
                           우리는 소비자들이 찾고 있는 교육에 과감히 대응합니다.<br className="pc_only" />
                           혁신적인 교육 세상, E-coin, E-pay World를 시작합니다.
                        </p>
                     </div>
                  </div>
                  <p className="xl:text-18 text-14 xl:leading-28 leading-22">
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                     Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                     ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                     cillum dolore eu fugiat nulla pariatur.

                     Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
               </div>
            </div>
         </section>
      </div>
   )
}