import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

export default function DetailPageControl({ navigationData }) {

   const query = useQuery();
   const page = query.get('page');
   const search = query.get('search');

   const location = useLocation();
   const [baseUrl, setBaseUrl] = useState('');

   useEffect(() => {
      const pathSegments = location.pathname.split('/').filter(Boolean); // 경로 세그먼트 분리 및 빈 요소 제거
      pathSegments.pop(); // 마지막 세그먼트 제거
      const newPath = pathSegments.length > 0 ? `/${pathSegments.join('/')}` : ''; // 새로운 경로 만들기
      const base = `${newPath}`; // 전체 URL 만들기
      setBaseUrl(base);
   }, [location]);

   return (
      <div className="control_wrap type_01 wrap">
         {navigationData.prev === null ? (
            <div className="btn prev noitem">
               <div className="prev_wrap">
                  <i className="xi-long-arrow-left xl:text-24"></i>
                  <p className="xl:text-16 font-bold">PREV</p>
               </div >
               <p className="tit xl:text-16 font-light">
                  이전 게시글이 없습니다.
               </p>
            </div >
         ) : (
            <Link to={`${baseUrl}/${navigationData.prev.id}?page=${page}&search=${search}`} className="btn prev">
               <div className="prev_wrap">
                  <i className="xi-long-arrow-left xl:text-24"></i>
                  <p className="xl:text-16 font-bold">PREV</p>
               </div >
               <p className="tit xl:text-16 font-light">
                  {navigationData.prev.title}
               </p>
            </Link >
         )
         }
         <Link className="btn_list" to={`${baseUrl}?page=${page}&search=${search}`}>
            <i className="xi-bars xl:text-24 icon"></i>
         </Link>
         {
            navigationData.next === null ? (
               <div className="btn next noitem">
                  <p className="tit xl:text-16 font-light">
                     다음 게시글이 없습니다.
                  </p>
                  <div className="next_wrap">
                     <p className="xl:text-16 font-bold">NEXT</p>
                     <i className="xi-long-arrow-right xl:text-24"></i>
                  </div>
               </div>
            ) : (
               <Link to={`${baseUrl}/${navigationData.next.id}?page=${page}&search=${search}`} className="btn next">
                  <p className="tit xl:text-16 font-light">
                     {navigationData.next.title}
                  </p>
                  <div className="next_wrap">
                     <p className="xl:text-16 font-bold">NEXT</p>
                     <i className="xi-long-arrow-right xl:text-24"></i>
                  </div>
               </Link>
            )
         }
      </div >
   )
}
