// Components Start
import SubPageBanner from "../../components/SubPageBanner";
import ContactUs from "../../components/ContactUs";
// Components End

// Images Start
import banner from "../../images/consumer-centric-education/it_based_math_education_service_banner.webp"
import itBasedMathEducationService_01 from "../../images/consumer-centric-education/it_based_math_education_service_01.webp"
// Images Start

export default function ItBasedMathEducationService() {
   return (
      <div className="it_based_math_education_service consumer_centric_educatio sub_page">
         <SubPageBanner
            category="소비자 중심의 교육"
            title="맞춤형 교육으로 다가갑니다"
            navDep_01="소비자 중심의 교육"
            navDep_02="IT 기반 수학 교육 서비스"
         />
         <section className="section01 xl:mb-120 mb-80 mb-64 wrap">
            <h3 className="tit type_bb xl:text-40 text-22 font-bold xl:pb-24 pb-16 xl:mb-24 mb-16"><span className="point">IT</span> 기반 수학 교육 서비스</h3>
            <p className="txt xl:text-20 text-13 xl:leading-32 leading-20">
               “과연 잘할 수 있을까?”, “지금 시작하기에는 너무 늦은 것 아닐까?” 새로운 시작을 두고 고민부터 앞서는 사람들에게 타샤 튜더는 말한다.<br className="pc_only" />
               “인생은 짧지 않나요? 하고 싶은 일이 있으면 하는 게 좋지요.” 동화책과 그림을 그려 받은 인세를 모아 구입한 버몬트주 산골의 30만 평의 황량한 대지를 환상적인 정원으로 가꿔낸
               타샤 튜더. 그가 오래도록 꿈꿔왔던 자신만의 정원을 가꾸기 시작한 건 쉰여섯이 되던 해였다.
            </p>
            <img className="xl:mt-48 mt-32 xl:mb-48 mb-32" src={banner} alt="" />
            <p className="xl:text-20 text-13 xl:leading-32 leading-20">
               타샤는 이 책에서 40여 년 동안 홀로 가꾼 정원의 시작과 최근 모습, 가꾸는 과정을 직접 안내한다.<br className="pc_only" />
               타샤 정원의 가장 최근 풍경과 함께 처음 정원을 구상했던 오래된 스케치와 정원의 기초를 만들던 시기의 흑백사진이 수록되어 있다.
            </p>
         </section>
         <section className="section02 wrap">
            <h3 className="xl:text-40 text-22 font-bold xl:mb-40 mb-32">IT 기반 수학 교육 서비스의 특징</h3>
            <ul className="item_warp">
               <li className="item item01">
                  <h4 className="tit xl:leading-32 leading-28 xl:text-22 text-18 font-bold">
                     <span className="point">아이들이 더 많이 보고, 더 많이 듣고, </span><br className="pc_only" />
                     더 많이 둘러볼 수 있는 환경
                  </h4>
                  <p className="bg_num xl:text-51 text-44 font-extrabold">01</p>
               </li>
               <li className="item item02">
                  <h4 className="tit xl:leading-32 leading-28 xl:text-22 text-18 font-bold">
                     경험하지 못한 세계를 이해하도록 <br className="pc_only" />
                     <span className="point">충분한 읽기 자료 제공</span>
                  </h4>
                  <p className="bg_num xl:text-51 text-44 font-extrabold">02</p>
               </li>
               <li className="item item03">
                  <h4 className="tit xl:leading-32 leading-28 xl:text-22 text-18 font-bold">
                     <span className="point">세계적인 지도자들과 학자들에 의한 </span><br className="pc_only" />
                     다큐멘터리와 강의
                  </h4>
                  <p className="bg_num xl:text-51 text-44 font-extrabold">03</p>
               </li>
            </ul>
         </section>
         <section className="section03 xl:mt-120 mt-80 xl:mb-120 mb-80 wrap">
            <div className="text_wrap">
               <h3 className="tit type_bb xl:text-40 text-22 font-bold xl:pb-24 pb-16 xl:mb-24 mb-16">IT 기반 수학 교육 서비스를 만나보세요.</h3>
               <p className="pc_only xl:text-20 text-13 xl:leading-32 leading-20">
                  “과연 잘할 수 있을까?”, “지금 시작하기에는 너무 늦은 것 아닐까?” 새로운 시작을 두고
                  고민부터 앞서는 사람들에게 타샤 튜더는 말한다.<br className="pc_only" />
                  “인생은 짧지 않나요? 하고 싶은 일이 있으면 하는 게 좋지요.” 동화책과 그림을 그려 받은
                  인세를 모아 구입한 버몬트주 산골의 30만 평의 황량한 대지를 환상적인 정원으로 가꿔낸
                  타샤 튜더. 그가 오래도록 꿈꿔왔던 자신만의 정원을 가꾸기 시작한 건 쉰여섯이 되던 해였다.
               </p>
            </div>
            <img className="mockup_img" src={itBasedMathEducationService_01} alt="" />
            <p className="txt mo_only xl:text-20 text-13 xl:leading-32 leading-20">
               “과연 잘할 수 있을까?”, “지금 시작하기에는 너무 늦은 것 아닐까?” 새로운 시작을 두고
               고민부터 앞서는 사람들에게 타샤 튜더는 말한다.
               “인생은 짧지 않나요? 하고 싶은 일이 있으면 하는 게 좋지요.” 동화책과 그림을 그려 받은
               인세를 모아 구입한 버몬트주 산골의 30만 평의 황량한 대지를 환상적인 정원으로 가꿔낸
               타샤 튜더. 그가 오래도록 꿈꿔왔던 자신만의 정원을 가꾸기 시작한 건 쉰여섯이 되던 해였다.
            </p>
         </section>
         <ContactUs />
      </div>
   )
}