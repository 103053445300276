import { useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import axios from "axios";
import DOMPurify from 'dompurify';

// Components Start
import DetailPageControl from "../../components/DetailPageControl"
// Components End

export default function LettersDetail() {

   const [data, setData] = useState();

   const { id } = useParams();
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const search = searchParams.get('search');

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/letters/${id}`)
            setData(response.data.data)
         } catch (error) {
            console.log(error)
         }
      }

      fetchData();
   }, [id, search])

   return (
      <div className="detail_common sub_page wrap xl:pt-48 pt-24">
         {data && <div className="detail_page_youtube_wrap">
            <iframe
               src={`https://www.youtube.com/embed/${data.youtube}`}
               title="youtube video"
               className="detail_page_youtube"
            ></iframe>
         </div>}
         <div className="info_wrap type_01 wrap xl:mb-40 mb-32">
            <h3 className="tit text-center xl:text-24 text-18 font-bold xl:mb-24 mb-16">
               {data && data.title}
            </h3>
            <div className="sub_info_wrap xl:mt-24 mt-16">
               <div className="date_wrap">
                  <p className="tit xl:text-16 text-13 font-semibold">Date</p>
                  <p className="txtt xl:text-16 text-13">{data && data.created_at}</p>
               </div>
               <div className="views_wrap">
                  <p className="tit xl:text-16 text-13 font-semibold">Views</p>
                  <p className="txt xl:text-16 text-13">{data && data.views}</p>
               </div>
            </div>
         </div>
         <div className="contents_wrap type_01 ql-editor xl:pb-80">
            {data && <div
               dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.content, {
                     ALLOWED_TAGS: ['iframe', 'p', 'div', 'a', 'span', 'b', 'i', 'u', 'br', 'strong', 'em'],
                     ALLOWED_ATTR: ['src', 'width', 'height', 'frameborder', 'allow', 'allowfullscreen', 'class', 'id', 'style', 'href', 'title', 'alt']
                  })
               }}
            ></div>}
         </div>
         {data && <DetailPageControl
            navigationData={data}
         />}
      </div>
   )
}