import { Link } from "react-router-dom";

export default function ContactUs() {
   return (
      <section className="contact_banner">
         <h3 className="text-center xl:text-32 text-20 xl:leading-48 leading-28 font-bold text-white">
            GOS는 교육 소비자에게 힘을 실어주는 제품을 제공하기 위해<br className="pc_only" />
            최선의 노력을 하고 있습니다.
         </h3>
         <Link className="btn_link xl:text-18 text-14 font-medium xl:mt-48 mt-32" to="/company/contact">문의하기</Link>
      </section>
   )
}