import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend) // HTTP 백엔드를 사용하여 번역 파일 로드
  .use(LanguageDetector) // 브라우저 언어 감지기 사용
  .use(initReactI18next) // React-i18next 바인딩 사용
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/translation.json' // 번역 파일 경로 설정
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // React는 이미 안전하게 이스케이프 처리를 함
    }
  });

export default i18n;