import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import axios from "axios";

// Components Start
import ProductPreview from "./ProductPreview";
// Components End

const ProductDetailPage = ({ t }) => {

   const [previewShow, setPreviewShow] = useState(false);

   const openPreview = () => {
      setPreviewShow(true);
   }
   const closePreview = () => {
      setPreviewShow(false);
   }

   const { id } = useParams();

   const [data, setData] = useState();
   useEffect(() => {
      const fetchData = async () => {
         const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/curriculums/${id}`)
         setData(response.data.data)
      }
      fetchData();
   }, [id])

   return (
      <div className="general_math_detailpage wrap xl:mt-195 mt-105 xl:mb-120">
         <div className="product_img_wrap">
            <img className="thumbnail" src={data && process.env.REACT_APP_API_URL + data.image.path} alt="대표 이미지" />
            {
               data && data.imageDetails.length >= 1 &&
               <button onClick={() => { openPreview() }} className="btn_preview">
                  <i className="xi-search xl:text-14 text-12"></i>
                  <p className="xl:text-18 text-14">미리보기</p>
               </button>
            }
         </div>
         {data ? (
            <div className="info_wrap xl:mt-0 mt-36">
               <div className="category_wrap">
                  <p className="category xl:text-16 text-11 font-bold">
                     {t("lang") === "ko" ? data.target.kr_name + "용" : (t("lang") === "en" ? data.target.en_name : data.target.zh_name)}
                  </p>
               </div>
               <h2 className="xl:leading-48 leading-28 xl:text-32 text-16 font-bold xl:mt-16 mt-16">{data.title}</h2>
               <ul className="xl:mt-32 mt-24 info_wrap">
                  <li className="info_item xl:mb-20 mt-13">
                     <strong className="tit leading-22 xl:text-18 text-13">분류</strong>
                     <p className="leading-22 xl:text-18 text-13">{data.classification}</p>
                  </li>
                  <li className="info_item xl:mb-20 mt-13">
                     <strong className="tit leading-22 xl:text-18 text-13">대상</strong>
                     <p className="leading-22 xl:text-18 text-13">
                        {t("lang") === "ko" ? data.target.kr_name : (t("lang") === "en" ? data.target.en_name : data.target.zh_name)}
                     </p>
                  </li>
                  <li className="info_item xl:mb-20 mt-13">
                     <strong className="tit leading-22 xl:text-18 text-13">형식</strong>
                     <p className="leading-22 xl:text-18 text-13">{data.form}</p>
                  </li>
                  <li className="info_item xl:mb-20 mt-13">
                     <strong className="tit leading-22 xl:text-18 text-13">저자</strong>
                     <p className="leading-22 xl:text-18 text-13">{data.author}</p>
                  </li>
                  <li className="info_item xl:mb-20 mt-13">
                     <strong className="tit leading-22 xl:text-18 text-13">출판</strong>
                     <p className="leading-22 xl:text-18 text-13">{data.publicated_at}</p>
                  </li>
                  <li className="info_item xl:mb-20 mt-13">
                     <strong className="tit leading-22 xl:text-18 text-13">가격</strong>
                     <p className="leading-22 xl:text-18 text-13">${data.price}</p>
                  </li>
                  <li className="info_item xl:mb-20 mt-13">
                     <strong className="tit leading-22 xl:text-18 text-13">ISBN</strong>
                     <p className="leading-22 xl:text-18 text-13">{data.isbn ? data.isbn : '-'}</p>
                  </li>
               </ul>
               <div className="store_wrap pt-24 mt-24">
                  <strong className="tit_wrap">
                     <i className="icon xi-shop xl:text-21"></i>
                     <p className="xl:text-18 text-16 font-bold">판매처</p>
                  </strong>
                  <ul className="store_item_wrap xl:mt-16 mt-16">
                     {data.publisher.map((publisher, index) => (
                        <li className="store_item" key={index}>
                           <Link className="btn_link xl:text-16 text-13 font-bold" to={publisher.url} target="_blank">{publisher.name}</Link>
                        </li>
                     ))}
                  </ul>
               </div>
            </div>
         ) : (
            <div>Loading</div>
         )}
         <ProductPreview
            data={data && data}
            show={previewShow}
            close={closePreview}
         />
      </div>
   )
}

export default withTranslation()(ProductDetailPage)