import { useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import axios from "axios";
import DOMPurify from 'dompurify';

// Components Start
import DetailPageControl from "../../components/DetailPageControl"
// Components End

export default function MathFarmDetail() {

   const [data, setData] = useState();

   const { id } = useParams();
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const search = searchParams.get('search');

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`)
            setData(response.data.data)
         } catch (error) {
            console.log(error)
         }
      }

      fetchData();
   }, [id, search])

   return (
      <div className="detail_common sub_page wrap">
         <div className="info_wrap type_01 wrap xl:mb-40 mb-32">
            <h3 className="tit text-center xl:text-24 text-18 font-bold xl:mb-24 mb-16">
               {data && data.title}
            </h3>
            <div className="sub_info_wrap xl:mt-24 mt-16">
               <div className="date_wrap">
                  <p className="tit xl:text-16 text-13 font-semibold">Date</p>
                  <p className="txtt xl:text-16 text-13">{data && data.created_at}</p>
               </div>
               <div className="views_wrap">
                  <p className="tit xl:text-16 text-13 font-semibold">Views</p>
                  <p className="txt xl:text-16 text-13">{data && data.views}</p>
               </div>
            </div>
         </div>
         <div className="contents_wrap type_01 ql-editor wrap">
            {data && <div
               className=""
               dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.content, {
                     ALLOWED_TAGS: ['iframe', 'p', 'div', 'a', 'span', 'b', 'i', 'u', 'br', 'strong', 'em'],
                     ALLOWED_ATTR: ['src', 'width', 'height', 'frameborder', 'allow', 'allowfullscreen', 'class', 'id', 'style', 'href', 'title', 'alt']
                  })
               }}
            ></div>}
            {data ? (
               data.file !== null &&
               <a href={`${process.env.REACT_APP_API_URL}/api/downloads?category=post&id=${data.id}`} className="btn_download">
                  <i className="icon xi-folder-open fs_type_11"></i>
                  <p className="txt fs_type_11 fw_type_03 file_name">{data.file.name}</p>
                  <i className="icon icon_download xi-download fs_type_11"></i>
               </a>
            ) : (
               <div className="icon_loading_wrap">
                  <i className="xi-spinner-2 xi-spin icon_loading"></i>
               </div>
            )
            }
         </div>
         {data && <DetailPageControl
            navigationData={data}
         />}
      </div>
   )
}