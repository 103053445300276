// Components Start
import SubPageBanner from "../../components/SubPageBanner";
// Components End

// Images Start
import curriculum01 from "../../images/liberal-arts-program/curriculum01.webp"
import curriculum02 from "../../images/liberal-arts-program/curriculum02.webp"
import curriculum03 from "../../images/liberal-arts-program/curriculum03.webp"
import curriculum04 from "../../images/liberal-arts-program/curriculum04.webp"
import curriculum05 from "../../images/liberal-arts-program/curriculum05.webp"
// Images End

export default function EducationCurriculum() {
   return (
      <div className="education_curriculum liberal_arts_program sub_page">
         <SubPageBanner
            category="Liberal Arts Program"
            title="맞춤형 교육으로 다가갑니다"
            navDep_01="Liberal Arts Program"
            navDep_02="교육 커리큘럼"
         />
         <section className="section01 wrap xl:mb-80 mb-80">
            <h3 className="tit type_bb xl:text-40 text-22 font-bold xl:pb-24 pb-16 xl:mb-24 mb-16">교육 커리큘럼</h3>
            <p className="xl:text-20 text-13 xl:leading-32 leading-20">
               “과연 잘할 수 있을까?”, “지금 시작하기에는 너무 늦은 것 아닐까?” 새로운 시작을 두고 고민부터 앞서는 사람들에게 타샤 튜더는 말한다.
               “인생은 짧지 않나요? 하고 싶은 일이 있으면 하는 게 좋지요.” 동화책과 그림을 그려 받은 인세를 모아 구입한 버몬트주 산골의 30만 평의 황량한 대지를 환상적인 정원으로 가꿔낸
               타샤 튜더. 그가 오래도록 꿈꿔왔던 자신만의 정원을 가꾸기 시작한 건 쉰여섯이 되던 해였다.
            </p>
            <img className="main_img xl:mt-80 mt-42" src={curriculum01} alt="커리큘럼" />
         </section>
         <section className="section02 wrap">
            <h3 className="tit xl:leading-64 leading-32 xl:text-40 text-22 font-bold xl:mb-40 mb-32"><span className="point">GOS</span>는 수학 공부에 <span className="point">가장 효과적인 교육 시스템</span>을 가지고 있습니다.</h3>
            <p className="xl:leading-32 leading-20 xl:text-20 text-13 xl:mb-80 mb-48">
               “과연 잘할 수 있을까?”, “지금 시작하기에는 너무 늦은 것 아닐까?” 새로운 시작을 두고 고민부터 앞서는 사람들에게 타샤 튜더는 말한다.
               “인생은 짧지 않나요? 하고 싶은 일이 있으면 하는 게 좋지요.” 동화책과 그림을 그려 받은 인세를 모아 구입한 버몬트주 산골의 30만 평의 황량한
               대지를 환상적인 정원으로 가꿔낸  타샤 튜더. 그가 오래도록 꿈꿔왔던 자신만의 정원을 가꾸기 시작한 건 쉰여섯이 되던 해였다.
            </p>
            <ul className="item_wrap">
               <li className="item">
                  <img className="main_img" src={curriculum02} alt="" />
                  <div>
                     <strong className="num xl:text-20 text-13">Chapter 1</strong>
                     <h4 className="xl:leading-48 leading-28 xl:text-32 text-20 font-bold xl:mt-24 mt-16">커리큘럼 1번의 내용을 넣어주세요.</h4>
                     <p className="xl:leading-32 leading-20 xl:text-20 text-13 xl:mt-32 mt-16">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                     </p>
                  </div>
               </li>
               <li className="item type_arrow">
                  <i className="icon xi-angle-down xl:text-32 text-20"></i>
               </li>
               <li className="item">
                  <img className="main_img" src={curriculum03} alt="" />
                  <div>
                     <strong className="num xl:text-20 text-13">Chapter 2</strong>
                     <h4 className="xl:leading-48 leading-28 xl:text-32 text-20 font-bold xl:mt-24 mt-16">커리큘럼 2번의 내용을 넣어주세요.</h4>
                     <p className="xl:leading-32 leading-20 xl:text-20 text-13 xl:mt-32 mt-16">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                     </p>
                  </div>
               </li>
               <li className="item type_arrow">
                  <i className="icon xi-angle-down xl:text-32 text-20"></i>
               </li>
               <li className="item">
                  <img className="main_img" src={curriculum04} alt="" />
                  <div>
                     <strong className="num xl:text-20 text-13">Chapter 3</strong>
                     <h4 className="xl:leading-48 leading-28 xl:text-32 text-20 font-bold xl:mt-24 mt-16">커리큘럼 3번의 내용을 넣어주세요.</h4>
                     <p className="xl:leading-32 leading-20 xl:text-20 text-13 xl:mt-32 mt-16">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                     </p>
                  </div>
               </li>
               <li className="item type_arrow">
                  <i className="icon xi-angle-down xl:text-32 text-20"></i>
               </li>
               <li className="item">
                  <img className="main_img" src={curriculum05} alt="" />
                  <div>
                     <strong className="num xl:text-20 text-13">Chapter 4</strong>
                     <h4 className="xl:leading-48 leading-28 xl:text-32 text-20 font-bold xl:mt-24 mt-16">커리큘럼 4번의 내용을 넣어주세요.</h4>
                     <p className="xl:leading-32 leading-20 xl:text-20 text-13 xl:mt-32 mt-16">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                     </p>
                  </div>
               </li>
            </ul>
         </section>
      </div>
   )
}