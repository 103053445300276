import { useState } from "react";
import SubPageBanner from "../../components/SubPageBanner";

import vision_mission_01 from "../../images/company/vision_mission_01.webp";
import vision_mission_02 from "../../images/company/vision_mission_02.webp";
import vision_mission_03 from "../../images/company/vision_mission_03.webp";
import vision_mission_04 from "../../images/company/vision_mission_04.webp";
import vision_mission_05 from "../../images/company/vision_mission_05.webp";
import vision_mission_06 from "../../images/company/vision_mission_06.webp";

export default function VisionMission() {
   const [activeTab, setActiveTab] = useState(0);

   const handleTabClick = (index) => {
      setActiveTab(index);
   };

   return (
      <div className="vision_mission company sub_page">
         <SubPageBanner
            category="회사소개"
            title="아름다운 수학의 세계에 환영합니다"
            navDep_01="회사소개"
            navDep_02="비전과 미션"
         />
         <section className="section01">
            <h3 className="text-center xl:text-40 text-22 font-bold">비전과 미션</h3>
            <p className="xl:text-20 text-13 xl:leading-32 leading-20 xl:mt-65 mt-24 text-center wrap xl:mb-80 mb-48">
               “과연 잘할 수 있을까?”, “지금 시작하기에는 너무 늦은 것 아닐까?” 새로운 시작을 두고 고민부터 앞서는 사람들에게 타샤 튜더는 말한다.
               “인생은 짧지 않나요? 하고 싶은 일이 있으면 하는 게 좋지요.” 동화책과 그림을 그려 받은 인세를 모아 구입한 버몬트주 산골의 30만 평의 황량한 대지를 환상적인 정원으로 가꿔낸
               타샤 튜더. 그가 오래도록 꿈꿔왔던 자신만의 정원을 가꾸기 시작한 건 쉰여섯이 되던 해였다.
            </p>
         </section>
         <section className="section02">
            <h5 className="xl:leading-32 leading-20 xl:text-32 text-20 font-medium text-center text-white xl:mb-40 mb-32">
               수학 교육의 <span className="text-white font-extrabold">글로벌 표준</span>을 만드는 <span className="text-white font-extrabold">General Onologic Soft</span>
            </h5>
            <p className="txt xl:text-20 text-13 xl:leading-32 leading-20 text-center text-white">
               “과연 잘할 수 있을까?”, “지금 시작하기에는 너무 늦은 것 아닐까?” 새로운 시작을 두고 고민부터 앞서는 사람들에게 타샤 튜더는 말한다.
               “인생은 짧지 않나요? 하고 싶은 일이 있으면 하는 게 좋지요.” 동화책과 그림을 그려 받은 인세를 모아 구입한 버몬트주 산골의 30만 평의 황량한 대지를 환상적인 정원으로 가꿔낸
               타샤 튜더. 그가 오래도록 꿈꿔왔던 자신만의 정원을 가꾸기 시작한 건 쉰여섯이 되던 해였다.
            </p>
            <ul className="list_wrap xl:mt-80 mt-42 mt-50">
               <li>
                  <img className="img" src={vision_mission_01} alt="" />
               </li>
               <li>
                  <img className="img" src={vision_mission_02} alt="" />
               </li>
               <li>
                  <img className="img" src={vision_mission_03} alt="" />
               </li>
            </ul>
         </section>
         <section className="section03 wrap xl:pt-120 pt-80">
            <p className="category xl:text-14 text-12 font-bold xl:mb-20 mb-12 text-center">PURSUE</p>
            <h3 className="xl:text-40 text-22 font-bold xl:mb-40 mb-32 text-center">GOS가 추구하는 수학교육</h3>
            <div className="tab_wrap">
               <ul className="tab_control_wrap">
                  <li className="control_item">
                     <button
                        className={`btn_control xl:text-20 text-13 font-bold ${activeTab === 0 ? "on" : ""}`}
                        onClick={() => handleTabClick(0)}
                     >
                        수학의 본질
                     </button>
                  </li>
                  <li className="control_item">
                     <button
                        className={`btn_control xl:text-20 text-13 font-bold ${activeTab === 1 ? "on" : ""}`}
                        onClick={() => handleTabClick(1)}
                     >
                        바람직한 수학교육
                     </button>
                  </li>
                  <li className="control_item">
                     <button
                        className={`btn_control xl:text-20 text-13 font-bold ${activeTab === 2 ? "on" : ""}`}
                        onClick={() => handleTabClick(2)}
                     >
                        제너럴매스
                     </button>
                  </li>
               </ul>
               <ul className="tab_item_wrap">
                  <li className={`tab_item ${activeTab === 0 ? "on" : ""}`}>
                     <strong className="tab_tit mo_only">수학의 본질</strong>
                     <div className="text_wrap">
                        <p className="xl:text-20 text-13 xl:leading-32 leading-20">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br className="pc_only"/>
                           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br className="pc_only"/>
                           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip<br className="pc_only"/>
                           ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse<br className="pc_only"/>
                           cillum dolore eu fugiat nulla pariatur.<br className="pc_only"/><br className="pc_only"/>

                           Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br className="pc_only"/>
                           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br className="pc_only"/><br className="pc_only"/>
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br className="pc_only"/>
                           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br className="pc_only"/>
                           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip<br className="pc_only"/>
                           ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse<br className="pc_only"/>
                           cillum dolore eu fugiat nulla pariatur.
                        </p>
                     </div>
                     <img className="img" src={vision_mission_04} alt="" />
                  </li>
                  <li className={`tab_item ${activeTab === 1 ? "on" : ""}`}>
                     <strong className="tab_tit mo_only">바람직한 수학교육</strong>
                     <div className="text_wrap">
                        <p className="xl:text-20 text-13 xl:leading-32 leading-20">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br className="pc_only"/>
                           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br className="pc_only"/>
                           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip<br className="pc_only"/>
                           ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse<br className="pc_only"/>
                           cillum dolore eu fugiat nulla pariatur.<br className="pc_only"/><br className="pc_only"/>

                           Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br className="pc_only"/>
                           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br className="pc_only"/><br className="pc_only"/>
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br className="pc_only"/>
                           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br className="pc_only"/>
                           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip<br className="pc_only"/>
                           ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse<br className="pc_only"/>
                           cillum dolore eu fugiat nulla pariatur.
                        </p>
                     </div>
                     <img className="img" src={vision_mission_05} alt="" />
                  </li>
                  <li className={`tab_item ${activeTab === 2 ? "on" : ""}`}>
                     <strong className="tab_tit mo_only xl:text-20 text-16">제너럴매스</strong>
                     <div className="text_wrap">
                        <p className="xl:text-20 text-13 xl:leading-32 leading-20">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br className="pc_only"/>
                           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br className="pc_only"/>
                           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip<br className="pc_only"/>
                           ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse<br className="pc_only"/>
                           cillum dolore eu fugiat nulla pariatur.<br className="pc_only"/><br className="pc_only"/>

                           Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br className="pc_only"/>
                           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br className="pc_only"/><br className="pc_only"/>
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br className="pc_only"/>
                           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br className="pc_only"/>
                           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip<br className="pc_only"/>
                           ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse<br className="pc_only"/>
                           cillum dolore eu fugiat nulla pariatur.
                        </p>
                     </div>
                     <img className="img" src={vision_mission_06} alt="" />
                  </li>
               </ul>
            </div>
         </section>
      </div>
   );
}
