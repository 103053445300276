import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import axios from "axios";

// Components Start
import SubPageBanner from "../../components/SubPageBanner";
import PageControl from "../../components/PageControl";
// Components End

const PreSchoolEducationCurriculum = ({ t }) => {

   const [data, setData] = useState();
   const [targetAge, setTargetAge] = useState("");
   const [pageLink, setPageLink] = useState(1);
   const [activeIndex, setActiveIndex] = useState(1);

   useEffect(() => {
      const fetchData = async () => {
         try {
            const url = `${process.env.REACT_APP_API_URL}/api/curriculums?lang=${t("lang")}&course=2&targetAge=${targetAge}&perPage=20&page=${pageLink}`
            const response = await axios.get(url)
            setData(response.data)
         } catch (error) {
            console.error(error)
         }
      }
      fetchData();
   }, [t, pageLink, targetAge])

   return (
      <div className="elementary_education_curriculum  kids_froggy_144 sub_page">
         <SubPageBanner
            category="키즈-프로기 144"
            title="맞춤형 교육으로 다가갑니다"
            navDep_01="키즈-프로기 144"
            navDep_02="유치부 교육 과정 교재"
         />
         <div className="wrap">
            <h3 className="text-center xl:leading-48 leading-32 xl:text-40 text-22 font-bold xl:mb-48 mb-32"><span className="color_main3">키즈-프로기144 : <br className="mo_only" /></span>유치부 교육 과정 교재</h3>
            <ul className="tab_wrap xl:mb-40 mb-32">
               <li className="tab_item">
                  <button onClick={() => { setTargetAge("") }} className={`btn_tab xl:text-18 text-14 font-semimold ${targetAge === "" ? "on" : ""}`}>전체</button>
               </li>
               <li className="tab_item">
                  <button onClick={() => { setTargetAge(7) }} className={`btn_tab xl:text-18 text-14 font-semimold ${targetAge === 7 ? "on" : ""}`}>K4 - 4세용</button>
               </li>
               <li className="tab_item">
                  <button onClick={() => { setTargetAge(8) }} className={`btn_tab xl:text-18 text-14 font-semimold ${targetAge === 8 ? "on" : ""}`}>K5 - 5세용</button>
               </li>
               <li className="tab_item">
                  <button onClick={() => { setTargetAge(9) }} className={`btn_tab xl:text-18 text-14 font-semimold ${targetAge === 9 ? "on" : ""}`}>K6 - 6세용</button>
               </li>
               <li className="tab_item">
                  <button onClick={() => { setTargetAge(10) }} className={`btn_tab xl:text-18 text-14 font-semimold ${targetAge === 10 ? "on" : ""}`}>K7 - 7세용</button>
               </li>
            </ul>
            <div className="product_list_wrap">
               {data && <p className="product_count xl:text-18 text-14">총 <span className="num font-bold">{data.meta.total}</span>개의 게시물</p>}
               <ul className="product_wrap xl:mt-24 mt-16">
                  {data ? (
                     data.data.map((product, index) => (
                        <li className="product_item" key={index}>
                           <Link to={`/curriculum/${product.id}`} className="btn_link type-search-wrap xl:mb-24 mb-16">
                              <img className="thumbnail" src={process.env.REACT_APP_API_URL + product.image.path} alt="썸네일" />
                              <div className="icon_search_wrap type01">
                                 <i className="xi-search xl:text-44 text-white"></i>
                              </div>
                           </Link>
                           <div className="category_wrap">
                              <div className="category xl:text-13 text-11 font-bold">
                                 {t("lang") === "ko" ? product.target.kr_name + "용" : (t("lang") === "en" ? product.target.en_name : product.target.zh_name)}
                              </div>
                              {product.imageDetails.length === 0 ? (
                                 ""
                              ) : (
                                 <div className="category xl:text-13 text-11 font-bold">미리보기</div>
                              )}
                           </div>
                           <strong className="product_tit xl:text-20 text-14 font-bold mt-10">{product.title}</strong>
                           <div className="price_wrap xl:mt-16 mt-16">
                              <p className="price_tit xl:text-14 text-12 font-semibold xl:pr-12 pr-8 xl:mr-12 mr-8">가격</p>
                              <p className="price xl:text-14 text-12">${product.price}</p>
                           </div>
                        </li>
                     ))
                  ) : (
                     <li className="hide_txt">Loading ...</li>
                  )}
                  {data && data.data.length === 0 && <li>게시글이 없습니다.</li>}
               </ul>
            </div>
            <PageControl
               data={data}
               pageLink={pageLink}
               setPageLink={setPageLink}
               activeIndex={activeIndex}
               setActiveIndex={setActiveIndex}
            />
         </div>
      </div>
   )
}

export default withTranslation()(PreSchoolEducationCurriculum)