import { useEffect } from "react"
import { Link } from "react-router-dom"

export default function NotFound() {

   useEffect(() => {
      let header = document.getElementById("header")
      let headerHeight = header.clientHeight

      let notfoundPage = document.querySelector('.notfound')
      notfoundPage.style.padding = `${headerHeight}px`

      header.classList.add("on")

      return () => {
         header.classList.remove("on");
      };
   }, [])

   return (
      <div className="notfound">
         <div className="txt_wrap">
            <h2 className="tit xl:text-44 text-22 font-bold xl:mb-16 mb-8">
               페이지를 찾을 수 없어요
            </h2>
            <p className="txt xl:text-20 text-13 xl:leading-28 leading-22 xl:mb-48 mb-32">
               방문하시려는 주소가 잘못되었거나,<br />
               페이지가 변경 또는 삭제된 것 같아요.
            </p>
            <Link to="/" className="btn_a">
               <p className="txt">홈으로</p>
            </Link>
         </div>
      </div>
   )
}