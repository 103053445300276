// Components Start
import SubPageBanner from "../../components/SubPageBanner";
// Components End

// Images Start
import overView01 from "../../images/liberal-arts-program/overview01.webp"
import overView02 from "../../images/liberal-arts-program/overview02.webp"
import overView03 from "../../images/liberal-arts-program/overview03.webp"
// Images End

export default function ProgramOverview() {
   return (
      <div className="program_overview liberal_arts_program sub_page">
         <SubPageBanner
            category="Liberal Arts Program"
            title="맞춤형 교육으로 다가갑니다"
            navDep_01="Liberal Arts Program"
            navDep_02="유아부 교육 과정"
         />
         <section className="section01 wrap">
            <h3 className="tit type_bb xl:text-40 text-22 font-bold xl:pb-24 pb-16 xl:mb-24 mb-16">프로그램 개요</h3>
            <p className="xl:text-20 text-13 xl:leading-32 leading-20">
               “과연 잘할 수 있을까?”, “지금 시작하기에는 너무 늦은 것 아닐까?” 새로운 시작을 두고 고민부터 앞서는 사람들에게 타샤 튜더는 말한다.
               “인생은 짧지 않나요? 하고 싶은 일이 있으면 하는 게 좋지요.” 동화책과 그림을 그려 받은 인세를 모아 구입한 버몬트주 산골의 30만 평의 황량한 대지를 환상적인 정원으로 가꿔낸
               타샤 튜더. 그가 오래도록 꿈꿔왔던 자신만의 정원을 가꾸기 시작한 건 쉰여섯이 되던 해였다.
            </p>
         </section>
         <section className="section02 wrap xl:mt-56 mt-56">
            <div className="content01 content">
               <img className="main_img" src={overView01} alt="" />
               <div className="text_wrap">
                  <strong className="num xl:mb-24 mb-16">01</strong>
                  <h4 className="tit xl:leading-48 leading-28 xl:text-32 text-20 font-bold xl:mb-24 mb-16">프로그램 내용 및 특징을 간략히 적어주세요.</h4>
                  <ul className="item_wrap">
                     <li className="item xl:mb-16 mb-8">
                        <i className="icon xi-check-circle-o xl:text-21"></i>
                        <p className="xl:leading-28 leading-22 xl:text-18 text-14">프로그램의 내용 및 특징에 대해 상세히 적어주세요.</p>
                     </li>
                     <li className="item xl:mb-16 mb-8">
                        <i className="icon xi-check-circle-o xl:text-21"></i>
                        <p className="xl:leading-28 leading-22 xl:text-18 text-14">프로그램의 내용 및 특징에 대해 상세히 적어주세요.</p>
                     </li>
                     <li className="item xl:mb-16 mb-8">
                        <i className="icon xi-check-circle-o xl:text-21"></i>
                        <p className="xl:leading-28 leading-22 xl:text-18 text-14">프로그램의 내용 및 특징에 대해 상세히 적어주세요.</p>
                     </li>
                  </ul>
               </div>
            </div>
            <div className="content02 content type02 xl:mt-80 mt-42 xl:mb-80 mb-48">
               <img className="main_img" src={overView02} alt="" />
               <div className="text_wrap">
                  <strong className="num xl:mb-24 mb-16">02</strong>
                  <h4 className="tit xl:leading-48 leading-28 xl:text-32 text-20 font-bold xl:mb-24 mb-16">프로그램 내용 및 특징을 간략히 적어주세요.</h4>
                  <ul className="item_wrap">
                     <li className="item xl:mb-16 mb-8">
                        <i className="icon xi-check-circle-o xl:text-21"></i>
                        <p className="xl:leading-28 leading-22 xl:text-18 text-14">프로그램의 내용 및 특징에 대해 상세히 적어주세요.</p>
                     </li>
                     <li className="item xl:mb-16 mb-8">
                        <i className="icon xi-check-circle-o xl:text-21"></i>
                        <p className="xl:leading-28 leading-22 xl:text-18 text-14">프로그램의 내용 및 특징에 대해 상세히 적어주세요.</p>
                     </li>
                     <li className="item">
                        <i className="icon xi-check-circle-o xl:text-21"></i>
                        <p className="xl:leading-28 leading-22 xl:text-18 text-14">프로그램의 내용 및 특징에 대해 상세히 적어주세요.</p>
                     </li>
                  </ul>
               </div>
            </div>
            <div className="content03 content">
               <img className="main_img" src={overView03} alt="" />
               <div className="text_wrap">
                  <strong className="num xl:mb-24 mb-16">03</strong>
                  <h4 className="tit xl:leading-48 leading-28 xl:text-32 text-20 font-bold xl:mb-24 mb-16">프로그램 내용 및 특징을 간략히 적어주세요.</h4>
                  <ul className="item_wrap">
                     <li className="item xl:mb-16 mb-8">
                        <i className="icon xi-check-circle-o xl:text-21"></i>
                        <p className="xl:leading-28 leading-22 xl:text-18 text-14">프로그램의 내용 및 특징에 대해 상세히 적어주세요.</p>
                     </li>
                     <li className="item xl:mb-16 mb-8">
                        <i className="icon xi-check-circle-o xl:text-21"></i>
                        <p className="xl:leading-28 leading-22 xl:text-18 text-14">프로그램의 내용 및 특징에 대해 상세히 적어주세요.</p>
                     </li>
                     <li className="item xl:mb-16 mb-8">
                        <i className="icon xi-check-circle-o xl:text-21"></i>
                        <p className="xl:leading-28 leading-22 xl:text-18 text-14">프로그램의 내용 및 특징에 대해 상세히 적어주세요.</p>
                     </li>
                  </ul>
               </div>
            </div>
         </section>
      </div>
   )
}