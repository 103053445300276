import React, { useEffect, useState } from "react";
import SubPageBanner from "../../components/SubPageBanner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import axios from "axios";

// components Start
import PageControl from "../../components/PageControl";
// components End

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

const MathFarm = ({ t }) => {

   const query = useQuery();
   const page = query.get('page');
   const search = query.get('search');

   const [data, setData] = useState();
   const [pageLink, setPageLink] = useState(page ? page : 1);

   const [searchTerm, setSearchTerm] = useState(search ? search : '');
   const [searchTermWrap, setSearchTermWrap] = useState(search ? search : '');
   const [activeIndex, setActiveIndex] = useState(page ? page : 1);

   const navigate = useNavigate();

   const handleChange = (event) => {
      setSearchTermWrap(event.target.value);
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      const regex = /^[a-zA-Z0-9가-힣\s]*$/;

      if (!regex.test(searchTermWrap)) {
         alert("영문[대소문자], 한글[자음+모음], 숫자로 입력해주세요");
         return;
      }

      setActiveIndex(1);
      setPageLink(1)
      setSearchTerm(searchTermWrap);
      navigate(`?page=${pageLink}&search=${searchTermWrap}`);
   };

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts?lang=${t("lang")}&course=1&perPage=10&page=${pageLink}&search=${searchTerm}`);
            setData(response.data);
            if (response.data.data.state === false) {
               alert("일시적인 오류가 발생했습니다.");
            }
         } catch (error) {
            console.log(error);
         }
      };


      fetchData();
      navigate(`?page=${pageLink}&search=${searchTerm}`);
   }, [t, searchTerm, pageLink, page, search, navigate]);

   return (
      <div className="math_farm blueberry_espresso type_notice sub_page">
         <SubPageBanner
            category="블루베리 에스프레소"
            title="블루베리 에스프레소 내용을 압력해주세요."
            navDep_01="블루베리 에스프레소"
            navDep_02="수학농장"
         />
         <div className="wrap">
            <div className="top_nav type_01">
               <h3 className="tit text-center xl:text-44 text-22 font-bold xl:mb-80 mb-24">수학농장</h3>
               <div className="top_item_wrap">
                  <p className="text_count xl:text-18 xl:mt-0 mt-24">총 {data ? (<strong className="point">{data.meta.total}</strong>) : (<strong className="point">0</strong>)}개의 게시물</p>
                  <form className="search_from" onSubmit={handleSubmit}>
                     <fieldset>
                        <legend className="hide">검색</legend>
                        <div className="search_box">
                           <input
                              id="search"
                              type="search"
                              name="search"
                              placeholder="검색어를 입력하세요"
                              value={searchTermWrap}
                              onChange={handleChange}
                           />
                           <button onClick={handleSubmit} type="button">
                              <i className="xi-search icon_search" />
                           </button>
                        </div>
                     </fieldset>
                  </form>
               </div>
            </div>
            <ul className="list_wrap">
               {
                  data ? (
                     data.meta.total === 0 ? (
                        <li className="item noitem txt_align_center">게시글이 없습니다.</li>
                     ) : (
                        data.data.map((list, index) => (
                           <li key={index} className="item">
                              <Link to={`/blueberry-espresso/math-farm/${list.id}?page=${pageLink}&search=${searchTerm}`} className="a_btn">
                                 <div>
                                    {list.fix === 1 && <p className="notice xl:text-16 text-14 xl:mb-12 mb-10 font-bold">공지</p>}
                                    <p className="tit xl:text-18 text-16 font-bold xl:mb-20 mb-13">{list.title}</p>
                                    <p className="date xl:text-16 text-14">{list.created_at}</p>
                                 </div>
                                 <div className="icon_wrap type_01 pc">
                                    <i className="icon xi-angle-right"></i>
                                 </div>
                              </Link>
                           </li>
                        ))
                     )
                  ) : (
                     <li className="hide_txt">Loading ...</li>
                  )
               }
            </ul>
            {data &&
               <PageControl
                  data={data}
                  pageLink={pageLink}
                  setPageLink={setPageLink}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
               />}
         </div>
      </div>
   );
}

export default withTranslation()(MathFarm)