import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

// Components Start
import SubPageBanner from "../../components/SubPageBanner";
import axios from "axios";
// Components End

const Membership = ({ t }) => {

   const [data, setData] = useState();
   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/memberships?lang=${t("lang")}`)
            setData(response.data.data)
         } catch (error) {
            console.error(error)
         }
      }
      fetchData();
   }, [t])

   return (
      <div className="membership liberal_arts_program sub_page">
         <SubPageBanner
            category="Liberal Arts Program"
            title="맞춤형 교육으로 다가갑니다"
            navDep_01="Liberal Arts Program"
            navDep_02="회원 가입"
         />
         <section className="section01 wrap">
            <h3 className="tit type_bb xl:text-40 text-22 font-bold xl:pb-24 pb-16 xl:mb-24 mb-16">회원 가입</h3>
            <p className="xl:leading-32 leading-20 xl:text-20 text-13">
               General Onologic Soft는 수학 교육의 새로운 글로벌 표준을 위한 길을 열고 있습니다.
               우리는 항상 교육 소비자에게 힘을 실어주는 제품을 제공하기 위해 최선의 노력을 하고 있습니다. 관심이 있으시면 언제든지 연락주세요.
            </p>
            <ul className="list_wrap01 xl:mt-56 mt-56">
               {data ? (
                  data.map((memberships, index) => (
                     <li className="list xl:p-48" key={index}>
                        <h4 className="tit xl:leading-48 leading-28 xl:text-32 text-20 font-bold xl:mb-24 mb-16">{memberships.title}</h4>
                        <strong className="price xl:text-18 text-14 font-medium xl:mb-24 mb-16">월 ${memberships.price}</strong>
                        <p className="xl:leading-28 leading-22 xl:text-18 text-14">{memberships.sub_title}</p>
                        <ul className="list_wrap02">
                           <li className="list type_dot xl:text-18 text-14">{memberships.content}</li>
                        </ul>
                     </li>
                  ))
               ) : (
                  <div className="hide_txt">Loading ...</div>
               )}
            </ul>
         </section>
      </div>
   )
}

export default withTranslation()(Membership)