import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';

// css Start
import 'swiper/css';
import 'swiper/css/pagination';
import axios from "axios";
// css Start

const Popup = ({t}) => {

   // fetch Data
   const [data, setData] = useState();
   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/popups?lang=${t("lang")}`)
            setData(response.data.data)
         } catch (error) {
            console.error(error)
         }
      }
      fetchData();
   }, [t])


   // 쿠키 설정 함수
   const setCookie = (name, value, days) => {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
   };

   // 쿠키 가져오기 함수
   const getCookie = (name) => {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0;i < ca.length;i++) {
         let c = ca[i];
         while (c.charAt(0) === ' ') c = c.substring(1, c.length);
         if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
   };

   const [isVisible, setIsVisible] = useState(true);

   useEffect(() => {
      const onedayCookie = getCookie('onedayPopup');
      if (onedayCookie) {
         setIsVisible(false);
      }
   }, []);

   const handleOnedayCheck = () => {
      setCookie('onedayPopup', 'true', 1);
      setIsVisible(false);
   };

   const handleClose = () => {
      setIsVisible(false);
   };

   if (!isVisible || !data || data.length === 0) {
      return null;
   }

   return (
      <div className="pop-parent" id="popParent" >
         <div className="pop">
            <Swiper
               autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
               }}
               loop={true}
               pagination={true}
               modules={[Autoplay, Pagination]}
               className="popupSwiper"
            >
               {
                  data ? (
                     data.map((popup, index) => (
                        <SwiperSlide key={index}>
                           {popup.link ? (
                              <Link to={popup.link} className="swiper-slide" target="_blank">
                                 <div className="m-ratioBox-wrap">
                                    <div className="m-ratioBox">
                                       <img src={process.env.REACT_APP_API_URL + popup.image.path} alt="" />
                                    </div>
                                 </div>
                              </Link>
                           ) : (
                              <div className="swiper-slide">
                                 <div className="m-ratioBox-wrap">
                                    <div className="m-ratioBox">
                                       <img src={process.env.REACT_APP_API_URL + popup.image.path} alt="" />
                                    </div>
                                 </div>
                              </div>
                           )}
                        </SwiperSlide>
                     ))
                  ) : (
                     <div className="hide_txt">Loading ...</div>
                  )
               }
            </Swiper>
            <div className="pop-btns">
               <input type="checkbox" name="oneday" id="oneday_check" onClick={handleOnedayCheck} />
               <label htmlFor="oneday_check">
                  <span className="icon"></span> 오늘하루 보지 않기
               </label>
               <button className="btn-close" onClick={handleClose}>
                  <i className="xi-close" style={{ color: "#fff" }}></i>
               </button>
            </div>
         </div>
      </div >
   )
}

export default withTranslation()(Popup)